<template>
	<div class="container-fluid my-Leads-md pt-3" v-if="reloadCompenent">
		<b-modal id="bv-modal-payement-status" centered scrollable hide-footer hide-header>
			<div class="d-block">
				<div class="row">
					<div class="col-md-12" style="padding: 10px 30px 40px;">
						<span
						style="float:right;cursor:pointer"
						@click="ClosePayementModal"
						>
						<i class="fa fa-times" style="float:right;font-size:18px"></i
						></span>
						<div
						v-if="this.payementStatut == 'success'"
						style="text-align: center"
						>
						<h3
							style="text-align: center;margin: 2rem 0;font-size: 1.4rem;color: #1d2841;"
						>
							{{ $t("Awsome") }}
						</h3>
						<i
							class="fa fa-check"
							style="margin: 2rem auto;width: 90px;height: 90px;font-size: 65px;color: rgb(76, 175, 80);display: block;line-height: 90px;border: 2px solid;text-align: center;border-radius: 50%;padding: 10px;box-sizing: content-box;"
						></i>
						<h3
							style="text-align: center;margin: 2rem 0;font-size: 14px;color: #1d2841;"
						>
							{{ $t("Yourpayementwassucceful") }}
						</h3>
						</div>

						<div
						v-if="this.payementStatut == 'error'"
						style="text-align:center;width: 80%;margin: auto;"
						>
						<i
							class="fa fa-exclamation-triangle "
							style="margin: 2rem auto;width: 90px;height: 90px;font-size: 65px;color: red;display: block;line-height: 90px;border: 2px solid;text-align: center;border-radius: 50%;padding: 10px;box-sizing: content-box;"
						></i>
						<h3
							style="text-align: center;margin: 2rem 0;font-size: 1.4rem;color: red;"
						>
							{{ $t("Oops !") }}
						</h3>

						<h3
							style="text-align: center;margin: 2rem 0;font-size: 14px;color: #1d2841;"
						>
							{{ $t("Yourpayementwasnotsucceful") }}
						</h3>
						<span
							@click="$bvModal.show('modal-plans')"
							style="background: rgb(233, 53, 41);color: rgb(255, 255, 255);font-size: 14px;width: 100%;height: 50px;padding: 6px 30px;margin: auto;box-sizing: border-box;border-radius: 5px;text-align: center;cursor: pointer;float: left;line-height: 40px;"
							><i
							class="fas fa-redo"
							style="color:#fff;margin-right:10px"
							></i>
							RETRY</span
						>
						</div>
					</div>
				</div>
			</div>
		</b-modal>
		<b-modal id="add_view" size="md" hide-footer title="Add view" @hidden="$bvModal.hide('add_view')">
			<div class="form-row m-0">
				<div class="form-group col-md-12 pl-0">
					<label for="name">Name <b style="color:red;">*</b></label>
					<input type="text" class="form-control" v-model="smartview.name" placeholder="" />
					<!--<small style="color: red;"> * Name required</small>-->
					<!--:placeholder="$t('write_the_email_reason_here')"-->
				</div>
				<div class="form-group col-md-12 pl-0">
					<div class="modal-actions">
						<button class="oml-btn outlined gray-btn mr-3" @click="$bvModal.hide('add_view');closeAddSmartViewModal();" >Cancel</button>
						<button class="oml-btn" @click="saveSmartView()">Save</button>
					</div>
				</div>
			</div>
		</b-modal>
		<div class="event_processing" v-show="loadingData">
			<!--i class="fa fa-spinner fa-spin fa-3x fa-fw"></i-->
			<img src="/img/ajax-loader.gif" />
		</div>
		
		<!-- demo root element -->
		<div id="demo" style="padding: 6px 10px 20px 10px;">
			
			<!--div>Selected Ids: {{ itemIds }}</div-->
			<!--date-range-picker
				ref="picker"
				:opens="opens"
				:locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }"
				:minDate="minDate" :maxDate="maxDate"
				:singleDatePicker="singleDatePicker"
				:timePicker="timePicker"
				:timePicker24Hour="timePicker24Hour"
				:showWeekNumbers="showWeekNumbers"
				:showDropdowns="showDropdowns"
				:autoApply="autoApply"
				v-model="dateRange"
				@update="updateValues"
				@toggle="checkOpen"
				:linkedCalendars="linkedCalendars"
				:dateFormat="dateFormat">
				<template v-slot:input="picker" style="min-width: 350px;">
					{{ picker.startDate | date }} - {{ picker.endDate | date }}
				</template>
			</date-range-picker-->
			
			<div class="row pb-3">
				<div class="col-md-2 pr-0">
					<div v-if="!$store.state.active || ($store.state.active && 'list leads' in $store.state.permissions)">
						<span style="font-size: 20px;" v-html="processing ? 'Loading ' : 'Leads'"></span>
						<span v-if="!$store.state.active || ($store.state.active && 'add leads' in $store.state.permissions)">
							<i class="fa fa-spinner fa-spin" v-if="processing"></i>
							<i class="fa fa-plus-circle text-primary c-pointer" v-else style="font-size: 17px; margin-left: 7px; " @click="ModalAddLead"></i>
						</span>
						
						
						<!--b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none" no-caret class="dropdown-md p-0" v-if="!processing"-->
							<!--template v-slot:button-content>
								<i class="fa fa-plus-circle" style=" font-size: 17px;vertical-align: top; "></i>
							</template-->
							<!--b-dropdown-item href="#">Edit status</b-dropdown-item-->
							<!--b-dropdown-item href="#" @click="$bvModal.show('bv-modal-changeStatus')">Edit Status</b-dropdown-item-->
							<!--b-dropdown-item href="#" @click="$bvModal.show('bv-modal-showHide-Col')">Show/Hide Col</b-dropdown-item-->
							<!--b-dropdown-item href="#" @click="$bvModal.show('bv-modal-addLead')">Add New Lead</b-dropdown-item-->
						<!--/b-dropdown-->
						
					</div>
					<div v-if="!$store.state.active || ($store.state.active && 'list leads' in $store.state.permissions)" class="d-flex align-items-center">
						<div style="font-size: 12px;display: inline-block;padding: 4px 0px;vertical-align: text-bottom;color: #2c3348;"><!-- padding: 4px 0px; -->
							<span v-if="itemIds.length == 0">{{ $t('Showing') }} ( {{ gridData.length }} / {{ totalLead }} ) {{ $t('leads') }}</span>
							<span style="white-space:nowrap;" v-else>{{ $t('YouHaveSelected') }} {{ itemIds.length }} {{ $t('leads') }}</span>
						</div>
						
						<b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none" no-caret class="bulkActions-dropdown dropdown-md dropdown-action" v-show="itemIds.length != 0" ref="dropdownBulk">
							<template v-slot:button-content v-if="!$store.state.active || ($store.state.active && 'bulk action' in $store.state.permissions)">
								<a style="font-size: 12px; vertical-align: super;">{{ $t('BulkActions') }} <i class="fa fa-caret-down"></i> </a>
							</template>
							<!--b-dropdown-item href="#" @click="bulkActions('sendSms')">Send SMS</b-dropdown-item-->
							<template v-for="(status) in myStatus">
								<b-dropdown-item href="#" v-bind:key="`status-${status.id}`" @click="bulkActions(status.id)">{{ status.title }}</b-dropdown-item>
							</template>

							<b-dropdown-form class="bulkActions-sendTo">
								<div><i class="fa fa-street-view"></i> Send to</div>
								<i class="ri-arrow-right-s-fill"></i>

								<div class="oml-detailsLeadSendTo-workspaces">
									<div class="oml-detailsLeadSendToWorkspace-content">
										<h3>Workspaces</h3>
										<ul>
											<li 
												class="checked" 
												v-for="workspace in $store.state.WorkSpaces" 
												:key="workspace.id"
											>
												<template v-if="workspace.id != $store.state.SelectedClient">
													<div class="workspace-item" @click="bulkActionSelectWorkspace(workspace.id)">
														<i v-if="bulkSendTo.worspace_id == workspace.id" class="ri-check-line"></i>
														<span>{{workspace.title}}</span>
													</div>
													
													<span class="workspace-total">{{workspace.tag}}</span>
												</template>
											</li>
										</ul>
									</div>
									<div class="oml-detailsLeadSendToWorkspace-footer">
										<div class="bulkBtn" @click="notifyMe('Choose a workspace', 'warn')" v-if="bulkSendTo.worspace_id == '' || bulkSendTo.worspace_id == null"><i class="icon icon-send"></i>Send</div>
										<div class="submitBtn" @click="bulkActionSend" v-else><i class="icon icon-send"></i>Send</div>
									</div>
								</div>
							</b-dropdown-form>

							<b-dropdown-form class="bulkActions-sendTo">
								<div><i class="fa fa-user"></i> Assigned to</div>
								<i class="ri-arrow-right-s-fill"></i>

								<div class="oml-detailsLeadSendTo-workspaces">
									<div class="oml-detailsLeadSendToWorkspace-content">
										<h3>Members</h3>
										<ul>
											<li 
												class="checked" 
												v-for="owner in owners" 
												:key="owner.id"
											>
												<div class="workspace-item" @click="bulkOwnerSelectWorkspace(owner.id)">
													<i v-if="bulkOwners.assignedTo == owner.id" class="ri-check-line"></i>
													<span>{{owner.title}}</span>
												</div>
											</li>
										</ul>
									</div>
									<div class="oml-detailsLeadSendToWorkspace-footer">
										<div class="bulkBtn" @click="notifyMe('Choose a member', 'warn')" v-if="bulkOwners.assignedTo == '' || bulkOwners.assignedTo == null"><i class="icon icon-send"></i>Assign</div>
										<div class="submitBtn" @click="bulkOwnerSend" v-else><i class="icon icon-send"></i>Assign</div>
									</div>
								</div>
							</b-dropdown-form>
							<b-dropdown-item 
								v-if="!$store.state.active || ($store.state.active && 'delete leads' in $store.state.permissions)"
								class="bulkActions-delete"
								@click="$bvModal.show('modal-delete-leads')"
							>
								<i class="fa fa-trash"></i> {{ $t('Delete') }}
							</b-dropdown-item>
							<!--b-dropdown-item href="#" v-if="itemIds.length != 0" @click="bulkActions('Qualified')">{{ $t('Qualify') }}</b-dropdown-item>
							<b-dropdown-item href="#" v-if="itemIds.length != 0" @click="bulkActions('Unqualified')">{{ $t('Unqualify') }}</b-dropdown-item>
							<b-dropdown-item href="#" v-if="itemIds.length != 0" @click="bulkActions('Archived')">{{ $t('Archive') }}</b-dropdown-item>
							<b-dropdown-item href="#" v-if="itemIds.length != 0" @click="$bvModal.show('delete-leads')">{{ $t('Delete') }} <i class="fa fa-trash" style="color:red;"></i></b-dropdown-item-->
							
							<!--b-dropdown-divider v-if="itemIds.length != 0"></b-dropdown-divider>
							<b-dropdown-item href="#" @click="bulkActions('Export')">Export to CSV</b-dropdown-item-->
						</b-dropdown>
					</div>
				</div>
				
				<div class="col-md-10 pl-0" style=""  v-if="!$store.state.active || ($store.state.active && 'list leads' in $store.state.permissions)">
					<div class="form-inline search-md" style="justify-content: flex-end;">
						
						<div class="form-group" style="width: 112px;" >
							<multi-select 
								faIcon="fa-user" 
								:options="owners" 
								:selected="tableSearch.assigned_user_id" 
								:label="$t('Owner')" 
								placeholderType="title" 
								:placeholder="$t('Owner')" 
								@filterChange="[getLeads(true, 'filter'),filterChanged = true]" 
								selectAll 
								:multiple="true"
								:caret="true"
							>
							</multi-select>
						</div>
						<div class="form-group ml-sm-2" style="width: 112px;" >
							<multi-select 
								faIcon="fa-filter" 
								:options="Filters" 
								:selected="tableSearch.selectedFilter" 
								:label="$t('Filter')" 
								placeholderType="title" 
								:placeholder="$t('Filter')" 
								@filterChange="[getLeads(true, 'filter'),filterChanged = true]"
								selectAll 
								multiple 
								:caret="true"
							>
							</multi-select>
						</div>
						<div class="form-group ml-sm-2" style="width: 160px;">
							<multi-select 
								faIcon="fa-tag" 
								:options="myStatus" 
								:selected="tableSearch.selectedStatus" 
								:label="$t('Statuses')" 
								placeholderType="title" 
								:placeholder="$t('Status')" 
								@filterChange="[getLeads(true, 'filter'),filterChanged = true]" 
								selectAll 
								multiple 
								:caret="true"
							>
							</multi-select>
						</div>
						<div class="form-group mx-sm-2" style="width: 180px;">
							<multi-select 
								faIcon="fa-calendar-check-o" 
								:options="mySources" 
								:selected="tableSearch.selectedSources" 
								:label="$t('MySources')" 
								placeholderType="title" 
								:placeholder="$t('Sources')" 
								@filterChange="[getLeads(true, 'filter'),filterChanged = true]" 
								:defaultSelected="optionsSelectSource.defaultSelected" 
								:selectAll="optionsSelectSource.selectAll" 
								multiple 
								:caret="true"
							></multi-select>
						</div>
						<div class="form-group" style="width: 160px;">
							<!--VueCtkDateTimePicker v-model="dateRange" @validate="getLeads(true, 'filter')" right range label="All Time" /-->
							<date-range-picker ref="picker" :showWeekNumbers="true" customRangeLabel= 'Custom Range' 
							:showDropdowns="true" v-model="dateRange" @update="[getLeads(true, 'filter'),filterChanged = true]">
								<template v-slot:input="picker" style="min-width: 350px;">
									<div :title="dateRange.startDate">
										<i class="fa fa-calendar d-block mr-1"></i>
										<span v-if="dateRange.startDate" class="range-span d-block">{{ $t('Customize') }}</span>
										<span v-else class="label-range">{{ $t('AllTime') }}</span> <!-- {{ dateRange.startDate }} - {{ dateRange.endDate }} -->
										<i class="fa fa-close init-dateRange" @click="InitDateRange" v-if="Object.keys(dateRange).length > 0"></i>
									</div>
								</template>
							</date-range-picker>
						</div>
						<transition name="show-enter">
							<div class="form-group input-searchZone-md mx-sm-2" v-if="inputFilterSearch"> <!--  has-search ml-sm-3  -->
								<i class="fa fa-search"></i>
								<input type="text" class="form-control input-searchZone" v-model="tableSearch.searchQuery" @keyup="[getLeads(true, 'filter'),filterChanged = true]" :placeholder="$t('Search')" />
								<button @click="inputFilterSearch = false"><i class="ri-close-line"></i></button>
							</div>
						</transition>
						<transition name="show-enter">
							<div class="form-group mx-sm-2" v-if="!inputFilterSearch">
								<div class="filter-option" @click="inputFilterSearch = true">
									<i class="fa fa-search"></i>
								</div>
							</div>
						</transition>
						<div class="form-group mr-2" v-if="!$store.state.active || ($store.state.active && 'show hide colums' in $store.state.permissions)" >
							<div class="filter-option" @click="$bvModal.show('bv-modal-showHide-Col')" id="popover-target-showHide">
								<i class="fa fa-columns m-auto"></i>
							</div>
							<b-popover target="popover-target-showHide" triggers="hover" placement="top">
								<!--template v-slot:title>Popover Title</template-->
								{{ $t('ShowHideColumns') }}
							</b-popover>
						</div>
						<div class="form-group">
							<div class="filter-option" @click="bulkActions('Export')" id="popover-export" v-if="['account_owner', 'admin'].includes(UserRole) || ($store.state.active && 'download leads' in $store.state.permissions)">
								<i class="fa fa-spinner fa-spin m-auto" v-if="processing"></i>
								<i class="fa fa-cloud-download m-auto" v-else></i>
							</div>
							<b-popover target="popover-export" triggers="hover" placement="top">
								<!--template v-slot:title>Popover Title</template-->
								{{ $t('ExportCsvFile') }}
							</b-popover>
						</div>
						<div class="form-group saveView-btn mx-sm-2" @click="addSmartView()" v-if="showSaveView">
							<i class="ri-pie-chart-2-fill"></i>
							Save View
						</div>
					</div>
				</div>
			</div>
			
			<div class="col-12"  v-if="DemoDataPanel && (!$store.state.active || ($store.state.active && 'list leads' in $store.state.permissions))">
				<div class="row mb-2">
					<div class="col-md-12 alert alert-warning m-0" style="font-size: 14px;">
						<i class="fa fa-info-circle" style="position: absolute;left: 5px;font-size: 42px;top: 2px;"></i>
						<div style="padding-left: 3em;">
							<span style="font-size: 14px;">{{ $t('DemoDataDesc') }} </span>
							
							<b @click="ClearDemoData" class="c-pointer">
								<i class="fa fa-spinner fa-spin" v-if="loadingAction"></i> {{ $t('ClearDemoData') }}
							</b>
						</div>
					</div>
					<!--div class="col-md-3 text-right my-auto">
						<span @click="ClearDemoData" class="c-pointer">
							<i class="fa fa-spinner fa-spin" v-if="loadingAction"></i>
							<img src="/img/icon/Clear-Demo-Data.png" v-else /> 
							{{ $t('ClearDemoData') }}
						</span>
					</div-->
				</div>
			</div>
			
			<data-table :myLeads="gridData" :demoData="DemoDataPanel" :demoDataSeen="demoDataPanelSeen" :columns="gridColumns" :activeDetailsLead.sync="activeDetailsLead" @actionClick="actionClick" :selectedLead="selectedLead" :ColumnBlur="ColumnBlur" v-if="!$store.state.active || ($store.state.active && 'list leads' in $store.state.permissions)"></data-table>
			<!-- :totalLead="totalLead" -->
			
			<router-link :to="{ name : 'newSource' }" v-if="gridData.length == 0 && (!$store.state.active || ($store.state.active && 'list leads' in $store.state.permissions))">
				<h5 class="sync-text" v-if="gridData.length == 0">
					<span>{{ $t('Sync') }}</span> {{ $t('SyncLeads') }}
				</h5>
			</router-link>
			<div v-if="$store.state.active && !Object.keys($store.state.permissions).includes('list leads')">
				<h5 class="sync-text">
					<img src="/img/svg/info-circle.svg" />
					<span style="text-decoration:none"> {{ $t('Noaccess') }}</span>
				</h5>
	
			</div>
			<!--b-modal id="bv-modal-changeStatus" centered size="lg" no-close-on-backdrop hide-footer hide-header>
			</b-modal-->

			<div class="oml-detailsLead-overlay" @click="activeDetailsLead = false" v-if="activeDetailsLead"></div>
			<div :class="[ activeDetailsLead ? 'oml-detailsLead-popup active' : 'oml-detailsLead-popup']">
				<div class="oml-detailsLead-container">
					<div class="oml-detailsLead-header">
						<div class="oml-detailsLeadHeader-left">
							<button class="oml-detailsLead-closeBtn" @click="activeDetailsLead = false">
								<i class="ri-close-line"></i>
							</button>
						</div>
						<div class="oml-detailsLeadHeader-right">
							<div class="oml-detailsLead-nav">
								<button @click="switchLeads('prev')" v-if="selectedLead.id > 0" class="oml-detailsLead-prev"><i class="ri-arrow-left-s-line"></i></button>
								<button @click="switchLeads('next')" v-if="gridData.length != selectedLead.id + 1" class="oml-detailsLead-next"><i class="ri-arrow-right-s-line"></i></button>
							</div>
							<b-dropdown 
								size="sm"  
								variant="link" 
								toggle-class="text-decoration-none" 
								right 
								no-caret 
								:class="[deleteSendToBtn ? 'margin-auto show' : 'margin-auto']"
								ref="dropdownFicheLead"
							>
								<template #button-content>
									<i class="ri-more-2-fill" @click="deleteSendToBtn = !deleteSendToBtn"></i>
								</template>
								
								<b-dropdown-form class="sendTo-btn">
									<div><i class="fa fa-street-view"></i> Send to </div><i class="ri-arrow-right-s-fill"></i>
									<div class="oml-detailsLeadSendTo-workspaces">
										<div class="oml-detailsLeadSendToWorkspace-content">
											<h3>Workspaces</h3>
											<ul>
												<li 
													class="checked" 
													v-for="workspace in $store.state.WorkSpaces" 
													:key="workspace.id"
													
												>
													<div v-if="workspace.id != $store.state.SelectedClient" class="workspace-item" @click="bulkActionSelectWorkspace(workspace.id,selectedLead.entry.id)">
														<i v-if="bulkSendTo.worspace_id == workspace.id" class="ri-check-line"></i>
														<span>{{workspace.title}}</span>
													</div>
													
													<span v-if="workspace.id != $store.state.SelectedClient" class="workspace-total">{{workspace.tag}}</span>
												</li>
											</ul>
										</div>
										<div class="oml-detailsLeadSendToWorkspace-footer">
											<div class="bulkBtn" @click="notifyMe('Choose a workspace', 'warn')" v-if="bulkSendTo.worspace_id == '' || bulkSendTo.worspace_id == null"><i class="icon icon-send"></i>Send</div>
											<div class="submitBtn" @click="bulkActionSend" v-else><i class="icon icon-send"></i>Send</div>
										</div>
									</div>
								
								</b-dropdown-form>
								
								<b-dropdown-item v-if="!$store.state.active || ($store.state.active && 'delete leads' in $store.state.permissions)" class="delete-btn" @click="$bvModal.show('modal-delete-lead')"><i class="ri-delete-bin-6-line"></i> {{ $t('Delete') }}</b-dropdown-item>
							</b-dropdown>
						</div>
						
					</div>

					<div class="oml-detailsLead-body">
						<div class="oml-detailsLeadBody-left">
							<div class="oml-detailsLeadLeft-header">
								<div class="oml-detailsLeadLeft-infos">
									<div class="oml-detailsLeadLeft-name">
										<h3 v-if="selectedLead.entry.full_name != ''">{{ selectedLead.entry.full_name | capitalize }}</h3>
										<!--button @click="doCall = !doCall" title="Popover Call" class="call-btn"><i class="ri-phone-line"></i></button-->
										<a :href="'tel:' + selectedLead.entry.personal_phone.split('.').join('')" @click="doCall = !doCall" title="Popover Call" class="call-btn"><i class="ri-phone-line"></i></a>
										<a :href="'https://wa.me/' + selectedLead.entry.personal_phone.split('.').join('')" class="whatsapp-btn"><i class="ri-whatsapp-line"></i></a>
									</div>
									<p><b>Source :</b> <span>{{ selectedLead.entry.source }}</span></p> 
									<p><b>Generated on :</b> <span>{{ selectedLead.entry.generatedOn }}</span></p>
								</div>
								<div class="oml-detailsLeadLeft-actions">
									<div class="d-flex align-items-center justify-content-end">
										<multiselect 
											v-model="selectedOwner"
											@close="sendOneOwnerSelected(selectedLead.entry.id)" 
											:selected="selectedLead.entry.assigned_user_id"
											:options="owners"
											:multiple="false" 
											:show-labels="false"
											:searchable="false" 
											:allow-empty="false"
											label="title"
											track-by="id"
											class="usersSelect"
										></multiselect>
										<select class="statusUserAction" v-if="!$store.state.active || ($store.state.active && 'qualify leads' in $store.state.permissions)" :style="statusStyle" v-model="selectedLead.entry.statusId" @change="changeStatusDetails(selectedLead.entry)" :disabled="UserRole == 'viewer'">
											<template v-for="(item, key) in allStatus">
												<option :value="item.id" v-text="item.option" v-bind:key="`stKey-${key}`"></option>
											</template>
										</select>
									</div>
									<div class="d-flex align-items-center justify-content-end" v-if="selectedLead.entry.status != 'Doublon' && selectedLead.entry.status != 'Duplicate' && selectedLead.entry.is_duplicate == true">
										<button class="duplicateBtn" @click="markAsADuplicate(selectedLead.entry)">{{$t('mark_as_a_duplicate')}}</button>
									</div>
								</div>
							</div>
							<div class="oml-detailsLeadLeft-content">
								<div class="custom-tabs setting-tabs">
									<b-tabs>
										<b-tab :title="$t('lead_details')" active>
											<div class="oml-leadDetails-container">
												<div class="oml-leadDetails-item">
													<div class="oml-leadDetails-label">
														<i class="fa fa-user"></i>
														{{ $t('FirstName') }}
													</div>
													<div class="oml-leadDetails-value">
														<input type="text" :placeholder="$t('FirstName')" v-model="selectedLead.entry.personal_first_name" @blur="changeDetailsLead('personal_first_name','',selectedLead.entry.personal_first_name)" :disabled="$store.state.active && !('edit leads' in $store.state.permissions)">
													</div>
												</div>
												<div class="oml-leadDetails-item">
													<div class="oml-leadDetails-label">
														<i class="fa fa-user"></i>
														{{ $t('LastName') }}
													</div>
													<div class="oml-leadDetails-value">
														<input type="text" :placeholder="$t('LastName')" v-model="selectedLead.entry.personal_last_name" @blur="changeDetailsLead('personal_last_name','',selectedLead.entry.personal_last_name)" :disabled="$store.state.active && !('edit leads' in $store.state.permissions)">
													</div>
												</div>
												<div class="oml-leadDetails-item">
													<div class="oml-leadDetails-label">
														<i class="icon icon-phone-square"></i>
														{{ $t('Phone') }}
													</div>
													<div class="oml-leadDetails-value d-flex flex-column align-items-end">
														<input type="text" :placeholder="$t('Phone')" v-model="selectedLead.entry.personal_phone" @blur="changeDetailsLead('personal_phone','',selectedLead.entry.personal_phone)" :disabled="$store.state.active && !('edit leads' in $store.state.permissions)">
														<small v-if="selectedLead.entry.phone_verified == 0 && selectedLead.entry.personal_phone != ''" class="small-md text-secondary"><i class="fa fa-info-circle"></i> </small>
														<small v-else-if="(selectedLead.entry.phone_verified == 1 || selectedLead.entry.phone_verified == 3 ) && selectedLead.entry.personal_phone != ''" class="small-md text-danger d-flex align-items-center"><i class="ri-close-line lh-19 fs-18 mr-1"></i>{{ $t('NotValid') }}</small>
														<small v-else-if="selectedLead.entry.phone_verified == 2 && selectedLead.entry.personal_phone != ''" class="small-md text-success"><i class="fa fa-check-square-o fs-13 mr-1"></i>{{ $t('Valid') }}</small>
													</div>
												</div>
												<div class="oml-leadDetails-item">
													<div class="oml-leadDetails-label">
														<i class="fa fa-envelope"></i>
														{{ $t('Email') }}
													</div>
													<div class="oml-leadDetails-value d-flex flex-column align-items-end">
														<input type="text" :placeholder="$t('Email')" v-model="selectedLead.entry.email" @blur="changeDetailsLead('email','',selectedLead.entry.email)" :disabled="$store.state.active && !('edit leads' in $store.state.permissions)">
														<small v-if="selectedLead.entry.email_verified == 0 && selectedLead.entry.email != ''" class="small-md text-secondary"><i class="fa fa-info-circle"></i></small>
														<small v-else-if="(selectedLead.entry.email_verified == 1 || selectedLead.entry.email_verified == 3) && selectedLead.entry.email != ''" class="small-md text-danger d-flex align-items-center"><i class="ri-close-line lh-19 fs-18 mr-1"></i>{{ $t('NotValid') }}</small>
														<small v-else-if="selectedLead.entry.email_verified == 2 && selectedLead.entry.email != ''" class="small-md text-success"><i class="fa fa-check-square-o fs-13 mr-1"></i>{{ $t('Valid') }}</small>
													</div>
												</div>
												<template v-if="selectedLead.entry.entry.length > 0">
													<div 
														:class="[item.type == 'new' ? 'oml-leadDetails-item newField' : 'oml-leadDetails-item']" 
														v-for="(item, key) in selectedLead.entry.entry" 
														v-bind:key="key"
													>
														<div class="oml-leadDetails-label" v-if="item.fieldSelected == 'custom_field' && item.name != '' && !item.type">
															<i class="fa fa-user"></i>
															{{ item.name }}
														</div>
														<div class="oml-leadDetailsLabel-textfield" v-else-if="item.fieldSelected == 'custom_field' && item.type">
															<div class="oml-leadDetails-input">
																<i class="fa fa-user"></i>
																<input 
																	type="text" 
																	placeholder="Enter name field" 
																	v-model="item.name"
																>
															</div>
															
														</div>
														<div class="oml-leadDetails-value" v-if="item.fieldSelected == 'custom_field' && item.value != '' && !item.type">
															<input type="text" placeholder="Enter value field" value="Mercedess forms - March Mercedess forms - March"  @blur="changeDetailsLead(item.name,'custom_field',item.value)">
														</div>
														<div class="oml-leadDetailsValue-textfield" v-else-if="item.fieldSelected == 'custom_field' && item.type">
															<input 
																type="text" 
																placeholder="Enter value field" 
																v-model="item.value" 
																@blur="changeDetailsLead(item.name,'custom_field',item.value)" 
																v-if="item.fieldSelected == 'custom_field' || item.name != ''" 
																:disabled="$store.state.active && !('edit leads' in $store.state.permissions)"
															>
														</div>
													</div>
												</template>
												<div class="oml-leadDetails-actions">
													<div class="leftSide">
														<!--button 
															v-if="!$store.state.active || ($store.state.active && 'edit leads' in $store.state.permissions)"
															@click="addField()" 
															v-show="anglet_value == 'lead_detail'"
															class="addField-btn"
														-->
														<button 
															v-if="!$store.state.active || ($store.state.active && 'edit leads' in $store.state.permissions)"
															@click="addField()" 
															v-show="anglet_value == 'lead_detail'"
															class="addField-btn"
														>
															<i class="ri-add-line"></i> {{ $t('AddField') }}
														</button>
														<button 
															v-if="!$store.state.active || ($store.state.active && 'edit leads' in $store.state.permissions)"
															@click="minusField(selectedLead.entry.entry)" 
															v-show="(selectedLead.entry.entry.length > 0 && countNewField > 0 && anglet_value == 'lead_detail')"
															class="minusField-btn"
														>
															<i class="ri-close-line"></i> {{ $t('MinusField') }}
														</button>
													</div>
												</div>
											</div>
										</b-tab>
										<b-tab 
											v-if="!$store.state.active || ($store.state.active && 'send email to leads' in $store.state.permissions)" 
											:title="$t('send_email')" 
											:active="!tabsFocus" 
											@click="test('send_email')"
										>
											<div class="oml-sendEmail-container">
												<div class="oml-sendEmail-content">
													<div class="oml-infos-box d-flex align-items-center">
														<i class="fa fa-info-circle fs-30 mr-3"></i>
														<p>
															{{$t('sendEmail_config_msg')}} <router-link  :to="{ path: '/account/smtp' }">{{$t('config_from_here')}}</router-link>.
															
														</p>
													</div>

													<b-row class="mt-3">
														<b-col cols="6">
															<div class="form-group">
																<label>{{$t('email_lead_to')}}</label>
																<div style="display:none">{{ emailArray.to = selectedLead.entry.email }} </div>
																<input type="text" class="form-control" :placeholder="$t('email_lead_to')" v-model="emailArray.to" disabled/>
															</div>
														</b-col>
														<b-col cols="6">
															<div class="form-group">
																<label>{{$t('email_lead_from')}}</label>
																<input type="text" class="form-control" :placeholder="$t('email_lead_from')" :value="smtp.email" disabled v-if="smtp" />
																<input type="text" class="form-control" :placeholder="$t('email_lead_from')" :value="$auth.user().email" disabled  v-else />
															</div>
														</b-col>
													</b-row>

													<b-row>
														<b-col cols="6">
															<div class="form-group">
																<label>{{$t('email_lead_subject')}}</label>
																<input type="text" class="form-control" :placeholder="$t('email_lead_subject')" v-model="emailArray.Subject" />
															</div>
														</b-col>
														<b-col cols="6">
															<div class="form-group">
																<label>Template</label>
																<div class="d-flex align-items-center">
																	<select class="form-control" @change="changeTemplate" v-model="emailArray.selectedTemplate">
																		<option value="">{{$t('email_add_template')}}</option>
																		<option v-for="(item, key) in emailArray.arrayEmailTemplates" v-bind:key="key" :value="key" v-html="item.title"></option>
																	</select>
																	<router-link :to="{ path: 'settings/email-templates'}" target="_blank">
																		<button class="oml-addNewTemp ml-2">
																			<i class="ri-add-line"></i>
																		</button>
																	</router-link>
																</div>
															</div>
														</b-col>
													</b-row>

													<b-row>
														<b-col cols="12">
															<div class="form-group">
																<label>Body</label>
																<vue-editor id="template_body" :editorOptions="editorOptions" ref="editorMd" v-model="emailArray.contentMessage" class="oml-vueEditor" placeholder="Body here ..."></vue-editor>
															</div>
														</b-col>
													</b-row>

													<b-row>
														<b-col cols="12" class="d-flex align-items-center justify-content-end">
															<button class="oml-btn lightGray-btn px-4" @click="discardEmail()">{{$t('email_lead_discard')}}</button>
															<button class="oml-btn ml-2 px-4" @click="sendEmail()">{{$t('email_lead_send')}}</button>
														</b-col>
													</b-row>
												</div>
											</div>
										</b-tab>
										<b-tab 
											title="Calendly" 
										>
											<div class="oml-calendly-container">
												<section class="ac-containeer">
													<div class="" v-if="url">
														<iframe :src="url" width="100%" height="400" name="search_iframe"></iframe>
													</div>
													<div v-else>
														<div class="oml-calendly-noResult">
															<img src="/img/no-calendly.png">
															<p>Schedule One-on-One and Group events right through Ohmylead</p>
															<button class="oml-btn green-btn" @click="ChangeRoute('integrations')">Configure</button>
														</div>
													</div>
												</section>
											</div>
										</b-tab>
										
									</b-tabs>
								</div>
							</div>
						</div>
						<div class="oml-detailsLeadBody-right">
								<div class="custom-tabs setting-tabs">
									<b-tabs>
										<b-tab :title="$t('activities')">
											<div class="oml-activities-container">
												<div class="oml-activities-content">
													<div class="oml-activities-body">
														<div class="oml-activities-actLine">
														
															<div class="oml-actLine-item light-yellow" v-for="(element, key) in selectedLead.entry.notes" v-bind:key="`notes-${key}`">
																<div class="oml-actLineItem-icon">
																	<i class="icon icon-note"></i>
																</div>
																<div class="oml-actLineItem-content">
																	<p class="header"><b>{{ $t('at') }}</b> {{ element.created_at }} <b>{{ $t('by') }}</b> <span v-b-tooltip.hover.topLeft v-b-tooltip="{customClass: 'activities-tooltip'}" :title="element.user.name">{{ element.user.name }}</span></p>
																	<p v-if="element.content != 'email_sent'">{{ element.content }}</p>
																	<!--p v-if="element.content != 'email_sent'" class="d-flex align-items-start">
																		<span class="substr ml-2" v-if="element.content.length > 24 && showMoreNote == -1" v-html="element.content"></span>
																		<span class="ml-2" v-else-if="element.content.length < 24 && showMoreNote == -1" v-html="element.content"></span>
																		<span class="ml-2" v-else-if="element.content.length < 24 && showMoreNote == key" v-html="element.content"></span>
																		<span class="ml-2" v-else-if="element.content.length > 24 && showMoreNote == key" v-html="element.content"></span>
																	</p-->
																	<!--p v-else>{{ $t(element.content) }}</p-->

																	<p v-else class="d-flex align-items-start">
																		<span class="substr ml-2" v-if="element.content.length > 24 && showMoreNote == -1" v-html="element.content"></span>
																		<span class="ml-2" v-else-if="element.content.length < 24 && showMoreNote == -1" v-html="element.content"></span>
																		<span class="ml-2" v-else-if="element.content.length < 24 && showMoreNote == key" v-html="element.content"></span>
																		<span class="ml-2" v-else-if="element.content.length > 24 && showMoreNote == key" v-html="element.content"></span>
																	</p>

																	<button class="span" v-if="element.content.length > 24 && showMoreNote == -1" @click="showMoreNote = key">Show more</button>
																	<button class="span" v-if="showMoreNote == key" @click="showMoreNote = -1">Show less</button>
																</div>
															</div>
															<div class="oml-actLine-item light-blue" v-for="(element, key) in selectedLead.entry.historic" v-bind:key="`${key}-${element.id}`">
																<div class="oml-actLineItem-icon">
																	<i class="icon icon-tag" v-if="element.action.id == 2 && element.currentColor != '' && element.currentColor != null"></i>
																	<i class="icon icon-person" v-else></i>
																</div>
																<div class="oml-actLineItem-content">
																	<p class="header"><b>{{ $t('at') }}</b> {{ element.created_at }} <b>{{ $t('by') }}</b> <span v-b-tooltip.hover.topLeft v-b-tooltip="{customClass: 'activities-tooltip'}" :title="element.user.name">{{ element.user.name }}</span></p>
																	
																	<p v-if="element.action.libelle != null">{{ element.action.libelle }}</p>

																	<p v-if="element.action.id == 1">{{ element.contenuactions }}</p>

																	<div class="oml-actLineItem-values d-flex align-items-center" v-else-if="element.action.id == 2">
																		<template v-if="element.currentColor != '' && element.currentColor != null">
																			<p><b>{{ $t('From') }}:</b> <span :style="{color:element.currentColor}">{{ element.firstactions | maxLength }}</span></p>
																			<p class="ml-3"><b>{{ $t('to') }}:</b><span :style="{color:element.previousColor}">{{ element.lastactions | maxLength }}</span></p>
																		</template>
																		<template v-else>
																			<p><b>{{ $t('From') }}:</b> <span v-b-tooltip.hover.topLeft v-b-tooltip="{customClass: 'activities-tooltip'}" :title="element.firstactions">{{ element.firstactions | maxLength }}</span></p>
																			<p class="ml-3"><b>{{ $t('to') }}:</b> <span v-b-tooltip.hover.topLeft v-b-tooltip="{customClass: 'activities-tooltip'}" :title="element.lastactions">{{ element.lastactions | maxLength }}</span></p>
																		</template>
																	</div>
																	<template v-else-if="element.action.id == 4">
																		<div class="oml-actLineItem-values d-flex align-items-center" v-if="element.entry.newemail != ''">
																			<p><b>{{ $t('From') }}:</b><span v-b-tooltip.hover.topLeft v-b-tooltip="{customClass: 'activities-tooltip'}" :title="element.entry.oldemail">{{ element.entry.oldemail | maxLength }}</span></p>
																			<p class="ml-3"><b>{{ $t('to') }}:</b> <span v-b-tooltip.hover.topLeft v-b-tooltip="{customClass: 'activities-tooltip'}" :title="element.entry.newemail">{{ element.entry.newemail | maxLength }}</span></p>
																		</div>
																		<div class="oml-actLineItem-values d-flex align-items-center" v-if="element.entry.newpersonalphone != ''">
																			<p><b>{{ $t('From') }}:</b><span v-b-tooltip.hover.topLeft v-b-tooltip="{customClass: 'activities-tooltip'}" :title="element.entry.oldpersonalphone">{{ element.entry.oldpersonalphone | maxLength }}</span></p>
																			<p class="ml-3"><b>{{ $t('to') }}:</b> <span v-b-tooltip.hover.topLeft v-b-tooltip="{customClass: 'activities-tooltip'}" :title="element.entry.newpersonalphone">{{ element.entry.newpersonalphone | maxLength }}</span></p>
																		</div>
																		<div class="oml-actLineItem-values d-flex align-items-center" v-if="element.entry.newpersonalfirstname != ''">
																			<p><b>{{ $t('From') }}:</b><span v-b-tooltip.hover.topLeft v-b-tooltip="{customClass: 'activities-tooltip'}" :title="element.entry.oldpersonalfirstname">{{ element.entry.oldpersonalfirstname | maxLength }}</span></p>
																			<p class="ml-3"><b>{{ $t('to') }}:</b><span v-b-tooltip.hover.topLeft v-b-tooltip="{customClass: 'activities-tooltip'}" :title="element.entry.newpersonalfirstname">{{ element.entry.newpersonalfirstname | maxLength }}</span></p>
																		</div>
																		<div class="oml-actLineItem-values d-flex align-items-center" v-if="element.entry.newpersonallastname != ''">
																			<p><b>{{ $t('From') }}:</b><span v-b-tooltip.hover.topLeft v-b-tooltip="{customClass: 'activities-tooltip'}" :title="element.entry.oldpersonallastname">{{ element.entry.oldpersonallastname | maxLength }}</span></p>
																			<p class="ml-3"><b>{{ $t('to') }}:</b><span v-b-tooltip.hover.topLeft v-b-tooltip="{customClass: 'activities-tooltip'}" :title="element.entry.newpersonallastname">{{ element.entry.newpersonallastname | maxLength }}</span></p>
																		</div>
																	</template>
																</div>
															</div>
															<div class="oml-actLine-item light-green" v-for="(assign, index) in selectedLead.entry.assigned_action" v-bind:key="`${index}-${assign.id}`">
																<div class="oml-actLineItem-icon">
																	<i class="icon icon-group fs-23"></i>
																</div>
																<div class="oml-actLineItem-content">
																	<p class="header"><b>{{ $t('at') }}</b> {{ assign.created_at }} <b>{{ $t('by') }}</b> <span v-b-tooltip.hover.topLeft v-b-tooltip="{customClass: 'activities-tooltip'}" :title="assign.user.name">{{ assign.user.name }}</span></p>
																	<div class="oml-actLineItem-values d-flex align-items-center">
																		<p><b>{{ $t('From') }}:</b><template v-if="assign.assigned_from"><span v-b-tooltip.hover.topLeft v-b-tooltip="{customClass: 'activities-tooltip'}" :title="assign.assigned_from.name">{{assign.assigned_from.name | maxLength}}</span></template><template><span v-b-tooltip.hover.topLeft v-b-tooltip="{customClass: 'activities-tooltip'}" title="Unassigned">Unassigned</span></template></p>
																		<p class="ml-3"><b>{{ $t('to') }}:</b><template v-if="assign.assigned_to"><span v-b-tooltip.hover.topLeft v-b-tooltip="{customClass: 'activities-tooltip'}" :title="assign.assigned_to.name">{{assign.assigned_to.name | maxLength}}</span></template></p>
																	</div>
																</div>
															</div>

															<div class="oml-actLine-item email-box" v-for="(email, key) in selectedLead.entry.email_historiques" v-bind:key="`${key}-${email.id}`">
																<div class="oml-actLineItem-icon">
																	<i class="icon icon-mail"></i>
																</div>
																<div class="oml-actLineItem-content d-block">
																	<p class="header"><b> {{ $t('at') }}</b> {{email.created_at}}<b> {{ $t('by') }} </b> <span v-b-tooltip.hover.topLeft v-b-tooltip="{customClass: 'activities-tooltip'}" :title="email.user.name">{{email.user.name | maxLength}}</span></p>
																	<p><b>Email sent to :</b> {{email.to}}</p>
																	<p><b>Subject :</b> {{email.subject}}</p>
																	<p class="d-flex align-items-start">
																		<b>Body :</b>
																		<span class="substr ml-2" v-if="email.body.length > 24 && email.seeMore == false" v-html="email.body"></span>
																		<span class="ml-2" v-else-if="email.body.length < 24 && email.seeMore == false" v-html="email.body"></span>
																		<span class="ml-2" v-else-if="email.body.length < 24 && email.seeMore == true" v-html="email.body"></span>
																		<span class="ml-2" v-else-if="email.body.length > 24 && email.seeMore == true" v-html="email.body"></span>
																	</p>
																	<button class="span" v-if="email.body.length > 24 && email.seeMore == false" @click="email.seeMore = !email.seeMore">Show more</button>
																	<button class="span" v-if="email.seeMore == true" @click="email.seeMore = !email.seeMore">Show less</button>
																</div>
															</div>

															<div class="oml-actLine-item light-blue">
																<div class="oml-actLineItem-icon">
																	<i class="icon icon-enter fs-23"></i>
																</div>
																<div class="oml-actLineItem-content">
																	<p class="header"><b>{{ $t('at') }}</b> {{ selectedLead.entry.generatedOn }} <b>{{ $t('by') }}</b> <span v-b-tooltip.hover v-b-tooltip="{customClass: 'activities-tooltip'}" :title="$auth.user().name">{{ $auth.user().name }}</span></p>
																	<p>{{ $t('NewLeadReceived') }}</p>
																</div>
															</div>


															<!--div class="oml-actLine-item light-blue">
																<div class="oml-actLineItem-icon">
																	<i class="ri-medal-2-line"></i>
																</div>
																<div class="oml-actLineItem-content">
																	<p class="header"><b>At</b> 10/09/2021 12:00:00</p>
																	<p>New lead received</p>
																</div>
															</div>
															<div class="oml-actLine-item light-blue">
																<div class="oml-actLineItem-icon">
																	<i class="ri-medal-2-line"></i>
																</div>
																<div class="oml-actLineItem-content">
																	<p class="header"><b>At</b> 10/09/2021 12:00:00 <b>by</b> Mehdi MOURABIT</p>
																	<div class="d-flex align-items-center">
																		<p><b>From</b> : <span class="color-red">Unqualified</span></p>
																		<p class="ml-3"><b>To</b> : <span class="color-blue">Pending</span></p>
																	</div>
																</div>
															</div>
															<div class="oml-actLine-item light-yellow">
																<div class="oml-actLineItem-icon">
																	<i class="ri-medal-2-line"></i>
																</div>
																<div class="oml-actLineItem-content">
																	<p class="header"><b>At</b> 10/09/2021 12:00:00 <b>by</b> Mehdi MOURABIT</p>
																	<p><b>Email sent to :</b> contact@webeuz.com</p>
																	<p><b>Subject :</b> Hello</p>
																	<p><b>Body :</b> Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mis.</p>
																	<a href="#">See more</a>
																</div>
															</div>
															<div class="oml-actLine-item light-blue">
																<div class="oml-actLineItem-icon">
																	<i class="ri-medal-2-line"></i>
																</div>
																<div class="oml-actLineItem-content">
																	<p class="header"><b>At</b> 10/09/2021 12:00:00 <b>by</b> Mehdi MOURABIT</p>
																	<div class="d-flex align-items-center">
																		<p><b>From</b> : Taoufik</p>
																		<p class="ml-3"><b>To</b> : Taoufik</p>
																	</div>
																</div>
															</div>
															<div class="oml-actLine-item light-yellow">
																<div class="oml-actLineItem-icon">
																	<i class="ri-medal-2-line"></i>
																</div>
																<div class="oml-actLineItem-content">
																	<p class="header"><b>At</b> 10/09/2021 12:00:00</p>
																	<p>New lead received</p>
																</div>
															</div>
															<div class="oml-actLine-item light-green">
																<div class="oml-actLineItem-icon">
																	<i class="ri-medal-2-line"></i>
																</div>
																<div class="oml-actLineItem-content">
																	<p class="header"><b>At</b> 10/09/2021 12:00:00</p>
																	<p>New lead received</p>
																</div>
															</div>
															<div class="oml-actLine-item light-blue">
																<div class="oml-actLineItem-icon">
																	<i class="ri-medal-2-line"></i>
																</div>
																<div class="oml-actLineItem-content">
																	<p class="header"><b>At</b> 10/09/2021 12:00:00</p>
																	<p>New lead received</p>
																</div>
															</div>
															<div class="oml-actLine-item light-blue">
																<div class="oml-actLineItem-icon">
																	<i class="ri-medal-2-line"></i>
																</div>
																<div class="oml-actLineItem-content">
																	<p class="header"><b>At</b> 10/09/2021 12:00:00</p>
																	<p>New lead received</p>
																</div>
															</div>
															<div class="oml-actLine-item light-yellow">
																<div class="oml-actLineItem-icon">
																	<i class="ri-medal-2-line"></i>
																</div>
																<div class="oml-actLineItem-content">
																	<p class="header"><b>At</b> 10/09/2021 12:00:00</p>
																	<p>New lead received</p>
																</div>
															</div>
															<div class="oml-actLine-item light-green">
																<div class="oml-actLineItem-icon">
																	<i class="ri-medal-2-line"></i>
																</div>
																<div class="oml-actLineItem-content">
																	<p class="header"><b>At</b> 10/09/2021 12:00:00</p>
																	<p>New lead received</p>
																</div>
															</div>
															<div class="oml-actLine-item light-blue">
																<div class="oml-actLineItem-icon">
																	<i class="ri-medal-2-line"></i>
																</div>
																<div class="oml-actLineItem-content">
																	<p class="header"><b>At</b> 10/09/2021 12:00:00</p>
																	<p>New lead received</p>
																</div>
															</div-->
														</div>
													</div>
													<div class="oml-activities-footer" v-if="!$store.state.active || ($store.state.active && 'add note to leads' in $store.state.permissions)">
														<textarea 
															name="" 
															id="" 
															cols="30" 
															rows="3" 
															v-model="textareaNote" 
															:disabled="UserRole == 'viewer'"
															:placeholder="$t('WriteNoteLead')"
														></textarea>
														<button @click="addNoteEvent">{{ $t('AddNote') }}</button>
													</div>
												</div>
											</div>
										</b-tab>
										<b-tab v-if="selectedLead.entry.is_duplicate === 1">
											<template #title>
												<div class="d-flex align-items-center justify-content-center">
													{{ $t('duplicates') }}<span class="oml-countDup-title">{{selectedLead.entry.duplicates_leads.length}}</span>
												</div>
											</template>

											<div class="oml-tabDuplicate-container">
												<div class="oml-tabDuplicate-content">
													<div class="oml-tabDuplicate-item" v-for="(duplicatedLead,id) in selectedLead.entry.duplicates_leads" :key="duplicatedLead.id">
														<div class="oml-tabDuplicateItem-header d-flex align-items-center justify-content-between">
															<h3 class="mb-0" v-if="duplicatedLead.personal_first_name != ''">{{duplicatedLead.personal_first_name}} {{duplicatedLead.personal_last_name}}</h3>
															<h3 class="mb-0" v-else>{{duplicatedLead.full_name}}</h3>
															<div class="oml-tabDuplicateItem-time">{{duplicatedLead.duration}}</div>
														</div>
														<div class="oml-tabDuplicateItem-content">
															<div class="oml-tabDuplicateItem-infos">
																<p>{{duplicatedLead.email}}</p>
																<p>{{duplicatedLead.personal_phone}}</p>
																<p v-if="duplicatedLead.status"><b>Status:</b> {{duplicatedLead.status}}</p>
																<p><b>Source :</b> {{duplicatedLead.source}}</p>
																<p><b>{{ $t('GeneratedOn')}} :</b> {{duplicatedLead.generatedOn}}</p>
															</div>
															<div class="oml-tabDuplicateItem-view">
																<button @click="myLeadDetail(duplicatedLead,id,'view')"><i class="ri-eye-line mr-0"></i></button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</b-tab>
									</b-tabs>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<b-modal id="bv-modal-sendSms" centered scrollable hide-footer hide-header>
				<div class="d-block">
					<div class="row">
						<div class="col-md-12" style="padding: 10px 30px 40px;">
							<h3 style="text-align: center;margin: 2rem 0;font-size: 1.4rem;color: #f6523c;">
								Send an SMS via your <strong>favorite Voip</strong> account to engage your leads
							</h3>
							<img style="display: block; margin: 2rem auto; width: 200px;" src="/img/svg/sms.svg">
							<!--a href="/communication/" target="_blank"></a-->
							<router-link :to="{name: 'callAndSms'}" target="_blank" class="btn btn-primary" style="margin: 0 auto;display: block;width: 340px;padding: 1rem;font-size: 1.2rem;background: #377cff;border-color: #387bff;">
								Connect
							</router-link>
						</div>
					</div>
					
				</div>
			</b-modal>
			
			
			<b-modal id="bv-modal-addLead" scrollable centered :title="$t('AddNewLead')">
				<div class="d-block">
					<div class="row mb-2">
						<!--div class="col-md-6 my-auto">
							<label class="m-0">Full Name <b style="color:red;">*</b></label>
						</div-->
						<div class="col-md-12">
							<input type="text" class="form-control" :placeholder="$t('FullName')" v-model="newLead.full_name" /> <!-- -plaintext -->
							<small class="help-block" v-if="validateField.full_name">{{ $t('PleaseFillOutThisField') }}</small>
						</div>
					</div>
					<div class="row mb-2">
						<!--div class="col-md-6 my-auto">
							<label class="m-0">First Name <b style="color:red;">*</b></label>
						</div-->
						<div class="col-md-12">
							<input type="text" class="form-control" :placeholder="$t('FirstName')" v-model="newLead.personal_first_name" /> <!-- -plaintext -->
							<!--small class="help-block" v-if="validateField.personal_first_name">Please fill out this field.</small-->
						</div>
					</div>
					<div class="row mb-2">
						<!--div class="col-md-6 my-auto">
							<label class="m-0">Last Name <b style="color:red;">*</b></label>
						</div-->
						<div class="col-md-12">
							<input type="text" class="form-control" :placeholder="$t('LastName')" v-model="newLead.personal_last_name" />
							<!--small class="help-block" v-if="validateField.personal_last_name">Please fill out this field.</small-->
						</div>
					</div>
					<div class="row mb-2">
						<!--div class="col-md-6 my-auto">
							<label class="m-0">Phone <b style="color:red;">*</b></label>
						</div-->
						<div class="col-md-12">
							<input type="phone" class="form-control" :placeholder="$t('Phone')" v-model="newLead.personal_phone" />
							<!--small class="help-block" v-if="validateField.personal_phone">Please fill out this field.</small-->
						</div>
					</div>
					<div class="row mb-2">
						<!--div class="col-md-6 my-auto">
							<label class="m-0">Email <b style="color:red;">*</b></label>
						</div-->
						<div class="col-md-12">
							<input type="email" class="form-control" :placeholder="$t('Email')" v-model="newLead.email" />
							<!--<small class="help-block" v-if="validateField.email">{{ $t('PleaseFillOutThisField') }}</small>-->
						</div>
					</div>
					<div class="row mb-2">
						<!--div class="col-md-6 my-auto">
							<label class="m-0">Status <b style="color:red;">*</b></label>
						</div-->
						<div class="col-md-12">
							<select class="form-control form-placeholder-color" v-model="newLead.status">
								<option disabled value="">{{ $t('Status') }}...</option>
								<option v-for="(item, key) in myStatus" v-bind:key="`addLead-${key}`" :value="item.id">{{ item.option }}</option>
							</select>
							<small class="help-block" v-if="validateField.status">{{ $t('PleaseFillOutThisField') }}</small>
						</div>
					</div>
					<div style="display:flex;">
						<p v-if="!$store.state.active || ($store.state.active && 'edit leads' in $store.state.permissions)" style="color: #377dff;font-weight: 500;font-size: 14px; cursor: pointer;" class="pt-2 m-0 mr-5" @click="arrayFieldsAddLead.push( { name: '', value: '' } )">
							<i class="fa fa-plus-circle"></i> {{ $t('AddField') }}
						</p>
						<p style="color: #ff5a5a;font-weight: 500;font-size: 14px; cursor: pointer;" class="pt-2 m-0" @click="arrayFieldsAddLead.splice(-1, 1)" v-show="arrayFieldsAddLead.length > 0" v-if="!$store.state.active || ($store.state.active && 'edit leads' in $store.state.permissions)">
							<i class="fa fa-minus-circle"></i> {{ $t('MinusField') }}
						</p>
					</div>
					<template v-if="arrayFieldsAddLead.length > 0">
						<div class="row" v-for="(item, key) in arrayFieldsAddLead" v-bind:key="key">
							<div class="col-md-6 pt-1 pb-1">
								<input type="text" class="form-control" :placeholder="$t('EnterNameField')" v-model="item.name" />
								<!--small class="help-block">Please fill out this field.</small-->
							</div>
							<div class="col-md-6">
								<input type="text" class="form-control" :placeholder="$t('EnterValueField')" v-model="item.value" />
							</div>
						</div>
					</template>
				</div>
				<template v-slot:modal-footer>
					<!--b-button class="oml-btn m-0 mr-2" @click="addNewLeads()">
						{{ $t('AddLead') }}</i>
					</b-button>
					<b-button class="m-0" size="sm" @click="$bvModal.hide('bv-modal-addLead')">{{ $t('Close') }}</b-button-->
					<div class="modal-actions">
						<button class="oml-btn outlined gray-btn mr-3" @click="$bvModal.hide('bv-modal-addLead')">{{ $t('Close') }}</button>
							<button class="oml-btn" @click="addNewLeads()">{{ $t('AddLead') }}</button>
					</div>
					
				</template>
			</b-modal>
			
			<b-modal id="bv-modal-showHide-Col" scrollable hide-footer hide-header>
				<div class="d-block" style="padding: 15px 30px 25px 30px !important;">
					<p class="border-bottom" style="padding-bottom: 8px;padding-top: 15px;margin-bottom: 1.5rem;font-weight: 600;">{{ $t('DisplayedColumns') }}</p>
					<div class="form-check mb-3 pl-5 pt-2 pb-2" v-for="(item, key) in gridColumns" v-bind:key="key" style="background-color: #dfeeff;border-radius: 4px;">
						<input class="form-check-input" type="checkbox" :value="item.column" :id="item.column" :checked="item.enable" @click="ShowHideCols(item)">
						<label class="form-check-label fs-14 w-100" :for="item.column">{{ item.title }}</label>
					</div>
					
				</div>
			</b-modal>
			
			<b-modal id="modal-delete-lead" centered hide-footer title="Please Confirm"><!--  size="sm" -->
				<div>
					<div class="row">
						<div class="col-12 mb-1">
							<p class="m-0" style=" color: #000; font-size: 14px; ">{{ $t('YouWantToDelete') }}</p>
							<div><b>{{ selectedLead.entry.personal_first_name | capitalize }} {{ selectedLead.entry.personal_last_name | capitalize }}</b></div>
							<div><b>{{ selectedLead.entry.email }}</b></div>
							<div><b>{{ selectedLead.entry.personal_phone }}</b></div>
						</div>
					</div>
					<div class="row">
						<div class="col-12 text-right">
							<b-button size="sm" class="mr-2" @click="$bvModal.hide('modal-delete-lead')">{{ $t('Cancel') }}</b-button>
							<b-button size="sm" variant="success" @click="confirmDelete()">
								<i class="fa fa-spinner fa-spin" v-if="loadingDeleteLead"></i> {{ $t('YesDelete') }}
							</b-button>
						</div>
					</div>
				</div>
			</b-modal>
			
			<b-modal id="modal-delete-leads" centered hide-footer title="Please Confirm"><!--  size="sm" -->
				<div>
					<div class="row">
						<div class="col-12 mb-1">
							<p class="m-0" style=" color: #000; font-size: 14px; ">{{ $t('ConfrmDeleteLeads') }}</p>
						</div>
					</div>
					<div class="row">
						<div class="col-12 text-right">
							<b-button size="sm" class="mr-2" @click="$bvModal.hide('modal-delete-lead')">{{ $t('Cancel') }}</b-button>
							<b-button size="sm" variant="success" @click="confirmDelete(itemIds)">
								<i class="fa fa-spinner fa-spin" v-if="loadingDeleteLead"></i> {{ $t('YesDelete') }}
							</b-button>
						</div>
					</div>
				</div>
			</b-modal>
			
			<b-modal id="delete-leads" centered hide-footer title="Please Confirm">
				<div>
					<div class="row">
						<div class="col-12 mb-1">
							<p class="m-0" style=" color: #000; font-size: 14px; ">
								{{ $t('Yes') }} <b>{{ itemIds.length }}</b> {{ $t('lead') }}<template v-if="itemIds.length > 1">s</template> ?
							</p>
						</div>
					</div>
					<div class="row">
						<div class="col-12 text-right">
							<b-button size="sm" variant="success" @click="bulkActions('Delete')">
								<i class="fa fa-spinner fa-spin" v-if="loadingDeleteLead"></i> {{ $t('Yes') }}
							</b-button>
						</div>
					</div>
				</div>
			</b-modal>
			
			<b-modal id="bv-modal-state" centered scrollable hide-footer hide-header size="lg">
				<div class="d-block p-4">
					<div class="row mb-4">
						<div class="col-md-6 d-flex">
							<!--i class="fa fa-check text-info my-auto" style="font-size: 30px;"></i-->
							<img src="/img/icon/check.png" class="my-auto" />
							<div class="ml-2">
								<div class="h5 m-0">{{ $t('Done') }}</div>
								<span style="font-size: 14px;">{{ $t('VerificationIsComplete') }}</span>
							</div>
						</div>
						<div class="col-md-6 text-right text-ohmylead">
							<span class="c-pointer" @click="SourceStats('hide')">{{ $t('HideResults') }} <img src="/img/icon/arrow-down.png" class="my-auto" /></span>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="row">
								<div class="col-12 mb-2">
									<div :class="['text-' + VariantArray.emails, 'h4 m-0']">{{ PercentArray.emails }}%</div>
									<span style="font-size: 12px;">{{ $t('OfEmailsAreValid') }}</span>
									<b-progress :value="PercentArray.emails" :variant="VariantArray.emails" style="height: 10px;"></b-progress>
								</div>
								<div class="col-12 mb-2">
									<div :class="['text-' + VariantArray.phones, 'h4 m-0']">{{ PercentArray.phones }}%</div>
									<span style="font-size: 12px;">{{ $t('OfPhonesAreValid') }}</span>
									<b-progress :value="PercentArray.phones" :variant="VariantArray.phones" style="height: 10px;"></b-progress>
								</div>
								<div class="col-12 mb-2">
									<div :class="['text-' + VariantArray.duplicate, 'h4 m-0']">{{ PercentArray.duplicate }}</div>
									<span style="font-size: 12px;">{{ $t('OfLeadsAreDuplicate') }}</span>
									<b-progress :value="PercentArray.duplicate" max="10" :variant="VariantArray.duplicate" style="height: 10px;"></b-progress>
								</div>
							</div>
						</div>
						<div class="col-md-6 text-right">
							<div class="canvas-wrap">
								<canvas id="canvas" width="300" height="300"></canvas>
								<span id="procent"></span>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12 text-center">
							<h3 style="font-size: 24px;">{{ $t('YouSeeHowEasyIts') }}</h3>
							<h6>{{ $t('YourTurnNow') }}</h6>
							<button class="btn btn-danger" style="height: 52px;width: 45%;" @click="$router.push({path: '/newsource'})">
								<img src="/img/svg/leadsources.svg" style="width: 25px;" /> <span style="font-size: 12px;">{{ $t('SyncYourFirstLeadSource') }}</span>
							</button>
						</div>
					</div>
				</div>
			</b-modal>
			
			<div class="m-popline row" v-if="ProcPanel">
				<div class="col-1 m-auto text-center">
					<img :src="ProcDemoData ? '/img/icon/check.png' : '/img/ajax-loader.gif'" />
				</div>
				<div class="col-11 m-auto">
					<h5  class="m-0" v-html="ProcDemoData ? 'Done' : 'Please wait ..'"></h5>
					<div style="font-size: 14px;" v-html="ProcDemoData ? 'Verification is complete' : 'Verification in progress'"></div>
					<span class="c-pointer show-stats text-ohmylead" v-if="ProcDemoData" @click="SourceStats('show')">
						{{ $t('ShowResults') }} <i class="fa fa-chevron-up"></i>
					</span>
					<i class="fa fa-close icon-close c-pointer" @click="ProcPanel = false"></i>
				</div>
			</div>
			
		</div>
</template>
<!--script src="https://cdn.jsdelivr.net/npm/vue/dist/vue.js"></script-->
<script>
	import dataTable from '@/plugins/md/dataTable/datatable-myleads.vue';
	import multiSelect from '@/plugins/md/multiselect/select-dropdown.vue';
	
	// Basic Use - Covers most scenarios
	
	/* Send Mail */
	import { VueEditor } from "vue2-editor";
	
	import XLSX from 'xlsx';
	import Pusher from 'pusher-js';
	
	import DateRangePicker from 'vue2-daterange-picker'
	import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'


	import Multiselect from 'vue-multiselect'
	//import detailsLead from './details-lead/detailsLead'
	
	export default {
		components: {
			dataTable,
			VueEditor,
			multiSelect,
			DateRangePicker,
			Multiselect,
			//detailsLead
		},
		data() {
			return {
				payementStatut: "",
				ColumnBlur		: 0,
				optionsSelectSource: {
					selectAll	: true,
					defaultSelected: ''
				},
				
				customFields: [
					{ field: "personal_gender", data: ""},
					{ field: "personal_title", data: ""},
					{ field: "personal_birthday", data: ""},
					{ field: "personal_website", data: ""},
					{ field: "personal_address1", data: ""},
					{ field: "personal_address2", data: ""},
					{ field: "personal_street", data: ""},
					{ field: "personal_house_number", data: ""},
					{ field: "personal_mobile", data: ""},
					{ field: "personal_fax", data: ""},
					{ field: "personal_postal", data: ""},
					{ field: "personal_city", data: ""},
					{ field: "personal_state", data: ""},
					{ field: "personal_country", data: ""},
					{ field: "personal_impressum", data: ""},
					
					{ field: "custom_field", data: ""},
					
				],
				
				loadingDeleteLead: false,
				loadingSaveChanges: false,
				textareaNote: '',
				searchCount	: 0,
				
				statusStyle : {backgroundColor: '#00bbf6', borderColor: '#00bbf6'},
				
				validateField	: {
					full_name: false, personal_first_name: false, personal_last_name: false, personal_phone: false, email: false, status: false
				},
				tabsFocus		: true,
				loadingData		: false,
				
				/* Email */
				emailArray: {
					Subject			: "",
					contentMessage	: "",
					selectedTemplate: "",
					from:'',
					to:'',
					arrayEmailTemplates: [],
				},
				/* Email */
				
				/* add new field */
				
				arrayFieldsAddLead	: [],
				/* add new field */
				
				/* customToolbar */
				customToolbar: [
					/* ["blockquote", "code-block"], */
					[{ header: [6, 5, 4, 3, 2, 1, false] }],
					["bold", "italic"],
					[ { list: "ordered" }, { list: "bullet" }/* , { list: "check" } */],
					[ { align: "" }, { align: "center" }, { align: "right" }, { align: "justify" } ],
					//[{ 'align': [] }],

					//[{ indent: "-1" }, { indent: "+1" }],
					//[{ color: ['#000000', '#663d00', '#f5523c', '#377dff', '#ccc', '#ffc107', '#28a745'] }, { background: [] }], // dropdown with defaults from theme
					//[{ 'font': [] }],
					// ["link", "image", "video"], 
					//["clean"] // remove formatting button
				],
				/* customToolbar */
				
				processing	: false,
				moreData	: false,
				
				gridColumns	: [
					{ title:  this.$t('Name')		   , column: 'full_name'  , enable: true},
					{ title: this.$t('Status')	   , column: 'status'	  , enable: true},
					{ title: this.$t('Email')	   , column: 'email'	  , enable: true},
					{ title: this.$t('Phone')	   , column: 'personal_phone', enable: true},
					{ title: this.$t('GeneratedOn'), column: 'generatedOn', enable: true},
					{ title: this.$t('Source')	   , column: 'source'	  , enable: false},
					// { title: 'Details'	   , column: 'details'	  , enable: true}
				],
				
				gridData: [],
				current_page: 1,
				last_page	: 6,
				totalLead	: 0,
				selectedLead : {
					"id": 0,
					"entry": { 
						"id"				: 0,
						"full_name"			: "",
						"personal_first_name": '',
						"personal_last_name": '',
						"status"			: "",
						"score"				: '',
						"email"				: "",
						"personal_phone"	: "",
						"generatedOn"		: "",
						"source"			: '',
						"color"				: "",
						"entry"				: [],
						"email_verified"	: "",
						"phone_verified"	: "",
						"details"			: "",
						"assigned_action"   : [],
						"assigned_user_id"  : "",
						"email_historiques"   : [],
					}
				},
				
				newLead: {
					full_name: '', personal_first_name: '', personal_last_name: '', personal_phone: '', email: '', status: ''
				},
				
				mySources : [],
				myStatus  : [],
				owners    : [],
				selectedOwner:[],
				selectedOwnerFicheLead:[],
				allStatus : [],
				Filters	  : [
					{ id: 'email_valid', title: this.$t('EmailValid') },
					{ id: 'phone_valid', title: this.$t('PhoneValid') },
					{ id: 'duplicate', title: this.$t('duplicate') },
				],
				itemIds	  : [],
				
				leadGetter: true,
				
				tableSearch: {
					searchQuery		: '',
					selectedSources	: [],
					selectedStatus	: [],
					selectedFilter	: [],
					selectedOwners	: [],
					assigned_user_id: [],
				},
				dateRange			: {},
				leadEnrichmensList  : [],
				dataEnrichmens		: {status: false, name:'',loading:false},
				
				showHideDetail	: false,
				
				countNewField	: 0,
				DemoDataPanel	: false,
				demoDataPanelSeen : true,
				loadingAction	: false,
				
				ProcDemoData	: false,
				ProcPanel		: false,
				
				SourceScore		: 0,	
				PercentArray	: { emails: 0, phones: 0, duplicate: 0 },
				VariantArray	: { emails: 'secondary', phones: 'secondary', duplicate: 'secondary' },
				srcInterval		: '',
				UserRole		: '',
				doCall          :false,
                connectStep1    :true,
                connectStep2    :false,
                toCall          :true,
                toRing          :false,
                toRingContent   :false,
                toHang          :false,
                showCallDetails :true,
                duplicates_leads:[],
                visible_collapse_1: true,
                visible_collapse_2: false,
                editorOptions: {
					modules: {
						toolbar: {
							container: [
								[{'placeholder': ['[[prospect_first_name]]','[[prospect_last_name]]','[[prospect_full_name]]', '[[prospect_phone]]', '[[prospect_email]]']}],
								[{'size': ['small', false, 'large', 'huge']}],
								[{'font': []}],
								['bold', 'italic', 'underline', 'link', {'color': []}],
								[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
								[{'direction': 'rtl'}],
								[{'align': []}],
								['clean']
							],
							handlers: {
								"placeholder": function (value) {
									if (value) {
										const cursorPosition = this.quill.getSelection().index;
										this.quill.insertText(cursorPosition, value);
										this.quill.setSelection(cursorPosition + value.length);
									}
								}
							}
						}
					}
                },
                anglet_value : 'lead_detail',
                smtp: {},
                userInfo: {},

				smartview:{
					id:0,
					name:'',
				},

				activeDetailsLead:false,
				deleteSendToBtn:false,
				leadOwnerSelected:null,
				bulkSendTo:{
					client_id: null,
					worspace_id: null,
					lead_id:[]
				},
				bulkOwners:{
					client_id: null,
					assignedTo: null,
					leads:[]
				},
				showMoreNote:-1,
				url: {
					'name'  : '',
					'email' : '',
				},
				filterChanged:false,
				filterClicked:false,
				showSaveView:false,
				inputFilterSearch:false,
			}
		},
		
		methods: {
			GetPayemetStatut() {
				this.payementStatut = this.$route.params.status;
				if (this.payementStatut) {
					this.$bvModal.show("bv-modal-payement-status");
				}
			},
			ClosePayementModal() {
				this.$bvModal.hide("bv-modal-payement-status");
			},
			GetTeamRole() {
				let that = this
				
				let url   = '/teammember/teamMembers'
				let method= 'POST'
				let data  = {}
				
				let UserCheck = JSON.parse( localStorage.getItem('oml-user') )
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					// that.arrayMembers = []
					let members = response.data.members

					that.owners.push({ title: 'Unassigned', id:0, enable: false})
					response.data.members.forEach( function(item) {
						that.owners.push({ title: item.name, id: item.id, enable: false})
					})
					that.owners.forEach( function(item) {
						that.tableSearch.assigned_user_id.push(item.id)
					})
					//that.owners.push({ title: item.name, column: item.type + '_' + item.libelle, enable: false })
					// console.log(members)
					members.forEach( function(member) {
						if( UserCheck.id == member.id ) {
							that.UserRole = member.role
						}
						// that.arrayMembers.push({id: member.id, name: member.name, email: member.email, role: member.role, role_id: member.role_id})
					} )
				}).catch(() => {
					
				})
				
			},
			
			ShowHideCols(col) {
				let that = this
				col.enable = !col.enable
				
				let SelectedCols = []
				that.gridColumns.forEach( function( item ) {
					if( item.enable ) {
						SelectedCols.push(item.column)
					}
				} )
				localStorage.setItem('selected-cols', JSON.stringify(SelectedCols) )
				
			},
			
			SourceStats(action) {
				let that = this
				if( action == 'show' ) {
					that.ProcPanel = false
					that.$bvModal.show('bv-modal-state')
					
					setTimeout( function() {
						that.arcMove('round', that.SourceScore)
					}, 400 )
					
				} else {
					that.ProcPanel = true
					that.$bvModal.hide('bv-modal-state')
				}
			},
			CheckSourceStats() {
				let that  = this
				
				let url   = 'myleads_info'
				let method= 'GET'
				let data  = {}
				
				that.ProcPanel = true
				clearInterval(that.srcInterval)
				that.srcInterval = setInterval (function() {
					
					that.$store.dispatch( 'apiRequest', { url, method, data } ).then((response) => {
						
						if( response.data.success ) {
							
							that.ProcDemoData	= true
							that.ProcPanel		= true
							let emails 		= response.data.emails
							let phones 		= response.data.phones
							let duplicate 	= response.data.duplicate
							that.PercentArray.emails 	= emails
							that.PercentArray.phones 	= phones
							that.PercentArray.duplicate = duplicate
							that.SourceScore			= response.data.score
							
							that.VariantArray.emails	= (emails < 10) ? 	 'danger'  : (emails < 60) ?  	'warning' : 'success'
							that.VariantArray.phones	= (phones < 10) ? 	 'danger'  : (phones < 60) ?  	'warning' : 'success'
							that.VariantArray.duplicate	= (duplicate == 0) ? 'success' : (duplicate < 3) ?  'warning' : 'danger'
							
							clearInterval(that.srcInterval)
							
							that.SourceStats('show')
							
							
						}
					}).catch(() => {
						clearInterval(that.srcInterval)
					})
					
				}, 15000);
				
			},
			
			CheckDemoData() {
				let that  = this
				
				let url   = 'newsource_info'
				let method= 'GET'
				let data  = {}
				that.$store.dispatch( 'apiRequest', { url, method, data } ).then((response) => {
					that.leftSidebarInfo()
					that.DemoDataPanel = ( response.data.demoData && response.data.leadsCount > 0 )
					that.demoDataPanelSeen = response.data.modal_displayed
					if(response.data.modal_displayed && ( response.data.demoData && response.data.leadsCount > 0 )) {
						that.CheckSourceStats()
					}
				}).catch(() => {
					
				})
			},
			
			ClearDemoData() {
				let that  = this
				
				that.loadingAction = true
				
				let url		= 'sources/delete_source/demo_data'
				let method	= 'DELETE'
				let data	= {provider : "delete sources", permissionid : 3}
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					
					let feedback = response.data
					if(feedback.success) {
						that.leftSidebarInfo()
						that.notifyMe(this.$t('ChangesSaved'), 'success')
						that.loadingAction = false
						that.$bvModal.hide('modal-delete-source');
						// window.location.href = '/myleads'
						that.$router.push( { path: '/newsource' } )
						
					}
					
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.loadingAction = false
				})
				
			},
			
			notifyMe(message, variant){
				this.$notify({
					group	: 'foo',
					// title: 'Important message',
					text	: message,
					type	: variant,
					// duration: 500000,
					// speed	: 1000
				});
			},
			InitDateRange () {
				let that = this
				that.dateRange = {}
				that.getLeads(true, 'filter')
			},
			ModalAddLead() {
				let that = this
				that.newLead.full_name = ''
				that.newLead.personal_first_name = ''
				that.newLead.personal_last_name = ''
				that.newLead.personal_phone = ''
				that.newLead.email = ''
              //that.newLead.status = ''
                that.getDefaultStatus()
				that.$bvModal.show('bv-modal-addLead')
			},
			targetOpen(link) {
				window.open(link, "_blank")
			},
			closeModalLeadDetails() {
				let that = this;
				that.countNewField = 0;
				that.getLeads(true,'filter')
				that.setCollapseParams();
				that.$bvModal.hide('bv-modal-leadDetails');
				that.leadEnrichmensList = [];
				that.discardEmail();
				that.anglet_value = 'lead_detail';
			},
			changeDetailsLead(action, field = '', value = '') {
				if(value != ''){
					let that = this
					that.selectedLead.entry.action = (field == 'custom_field') ? 'entry' : action
					let url   = 'leads/update_lead'
					let method= 'PATCH'
					let data  = that.selectedLead.entry
					data.provider = "edit leads"
					that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
						if( action != field && response.data.success ) {
							that.notifyMe(this.$t('ChangesSaved'), 'success')
							that.gridData[ that.selectedLead.id ].historic = response.data.historiques
							that.selectedLead.entry.historic = response.data.historiques
							if( action == 'email' || action == 'personal_phone' ) {
								that.selectedLead.entry.email_verified = response.data.emailCheck
								that.selectedLead.entry.phone_verified = response.data.phoneCheck
							}
						}
					}).catch(( {response} ) => {
						console.log(response)
					})
				} else {
					//alert('changeDetailsLead')
				}
			},
			minusField(entry) {
				let that = this;
				if(that.countNewField > 0) {
					entry.splice(-1,1);
					that.changeDetailsLead('custom_field','custom_field','aaaaaa');
					that.countNewField--;
				}
			},
			addField() {
				this.countNewField++;
				//this.newFieldsIndexes.push(this.selectedLead.entry.entry.length)
				this.selectedLead.entry.entry.push( { name: '', value: '', fieldSelected:'custom_field', type:'new' } )
			},
			
			addNoteEvent() {
				let that = this
				if( that.textareaNote != '' ) {
					that.loadingSaveChanges = true
					
					let url   = 'leads/save_note'
					let method= 'POST'
					let data  = {selectedLead: that.selectedLead.entry.id, textareaNote:that.textareaNote, provider : "add note to leads", permissionid : 14}
					
					that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
						
						that.gridData[ that.selectedLead.id ].historic = response.data.historiques
						that.gridData[ that.selectedLead.id ].notes = response.data.notes
						that.selectedLead.entry.historic = response.data.historiques
						that.selectedLead.entry.notes = response.data.notes
						that.loadingSaveChanges = false
						
						that.notifyMe(this.$t('ChangesSaved'), 'success')
						
						that.textareaNote = ''
						
					}).catch(( {response} ) => {
						that.notifyMe(response.data.message, 'warn')
					})
					
				} else {
					that.notifyMe('Field Note Required !', 'warn')
				}
			},
			changeStatusDetails(entry) {
				let that = this
				that.allStatus.forEach( function(item) {
					if ( item.id == entry.statusId  ) {
						that.statusStyle.backgroundColor = item.color
						that.statusStyle.borderColor	 = item.color
						entry.status = item.title
						that.gridData[ that.selectedLead.id ].status = item.title
						that.gridData[ that.selectedLead.id ].color  = item.color
					}
				})
				let url   = 'leads/change_status'
				let method= 'PATCH'
				let data  = {leadId: entry.id, newStatus: entry.statusId, provider : "edit status", permissionid : 21}
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					if( response.data.success ) {
						if( response.data.message) {
							let myMessage = response.data.message
							that.notifyMe(myMessage,'success')
						}
						if( response.data.sent ) {
							let message = 'Lead has been sent'
							that.notifyMe(message, 'success')
						}
						that.gridData[ that.selectedLead.id ].historic = response.data.historiques
						that.selectedLead.entry.historic = response.data.historiques
						that.gridData[ that.selectedLead.id ].notes = response.data.notes
						that.selectedLead.entry.notes = response.data.notes
						that.$store.state.reloadLeftSidebarCompenent = Math.random();
						that.$store.state.checkChange = true;
						that.getLeads(true, 'filter')
					}	
				}).catch(( {response} ) => {
					console.log(response)
				})
			},
			
			updateValue (value) {
				value = value.hex
				return value.hex
			},
			
			downloadFile(dataArray) {
				let that  = this
				var today = new Date();
				var dd 	  = String(today.getDate()).padStart(2, '0');
				var mm 	  = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
				var yyyy  = today.getFullYear();
				
				today = mm + '-' + dd + '-' + yyyy;
				
				const data = XLSX.utils.json_to_sheet(dataArray)
				const wb = XLSX.utils.book_new()
				XLSX.utils.book_append_sheet(wb, data, 'data')
				XLSX.writeFile(wb,'myLeads-('+today+')-file.' + 'csv') // csv - xlsx
				that.processing = false
			},
			
			bulkActions(action) {
				
				let that = this
				if( action == 'sendSms' ) {
					that.$bvModal.show('bv-modal-sendSms')
					// this.totalLead
				} else if ( action == 'Export' ) {
					
					let dataArray = []
					that.processing = true
					if( that.itemIds.length != 0 ) {
						
						that.gridData.forEach( function(val) {
							
							let idLead = parseInt(val.id);
							
							if ( that.itemIds.includes( idLead ) ) {
								
								let arDt = [], field = ''
								that.gridColumns.forEach( function(item) {
									if( /* item.enable && */ item.column != 'id' && item.column != 'details' ) {
										// field = item.column
										// arDt[field] = val[field] != null ? val[field] : ''
										field = item.column.replace('custom_', '')
										arDt[field] = val[item.column] != null ? val[item.column] : ''
									}
								} )
								let notes = val.notes != null ? val.notes : [], title = (arDt['notes'] != '' && arDt['notes'] != null) ? 'lead notes' : 'notes'
								arDt[title] = ''
								notes.forEach( function(note) {
									arDt[title] += '- ' + note.content + "\n"
								} )
								dataArray.push(arDt)
							}
						} )
						that.downloadFile(dataArray)
						that.processing = false
					} else {
						
						that.loadingData = true
						that.processing = true
						
						let method= 'POST'
						let data  = { 
							searchQuery:that.tableSearch.searchQuery,
							selectedSources:that.tableSearch.selectedSources,
							selectedStatus:that.tableSearch.selectedStatus,
							selectedFilter:that.tableSearch.selectedFilter,
							dateRange:that.dateRange
						}
						let url   = 'leads'
						
						that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
							
							let myLeads = response.data.leads
							myLeads.forEach( function(val) {
								
								let arDt = [], field = ''
								that.gridColumns.forEach( function(item) {
									if( /* item.enable && */ item.column != 'id' && item.column != 'details' ) {
										if ( item.column == 'status' ) {
											arDt[item.column] = val.param.state
										} else if ( item.column == 'generatedOn' ) {
											arDt[item.column] = val.created_at
										} else if ( item.column == 'source' ) {
											arDt[item.column] = val.source.name
										} else {
											if( item.column.match(/custom_/) ) {
												field = item.column.replace('custom_', '')
												arDt[field] = val.entry[field] != null ? val.entry[field] : ''
											} else {
												field = item.column
												arDt[field] = val[field] != null ? val[field] : ''
											}
										}
									}
								} )
								let notes = val.notes != null ? val.notes : [], title = (arDt['notes'] != '' && arDt['notes'] != null) ? 'lead notes' : 'notes'
								arDt[title] = ''
								notes.forEach( function(note) {
									arDt[title] += '- ' + note.content + "\n"
								} )
								dataArray.push(arDt)
								
							} )
							
							that.loadingData = false
							that.processing = false
							that.downloadFile(dataArray)
							
						}).catch(( response ) => {
							console.log(response) 
							that.loadingData = false
							that.processing = false
						})
						
					}
					
				} else {
					that.BulkChangeStatus(action)
				}
				
			},
			
			BulkChangeStatus(action) {
				let that  = this
				let url   = 'leads/bulk_status'
				let method= 'POST'
				let data  = { leads:that.itemIds, action:action, provider : "qualify leads", permissionid : 13}
				that.loadingDeleteLead = true
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					if( response.data.success ) {
						if( response.data.sent ) {
							let message = that.itemIds.length > 1 ? 'Leads have been sent' : 'Lead has been sent'
							that.notifyMe(message, 'success')
						} else {
							that.notifyMe('Status Changed !', 'success')
						}
					}
					
					that.getLeads(true, 'filter')
					that.leftSidebarInfo()
					that.$bvModal.hide('delete-leads')
					that.loadingDeleteLead = false
				}).catch(( /* {response} */ ) => {
					that.loadingDeleteLead = false
				})
			},
			
			confirmDelete(leads) {
				
				let that = this
				that.loadingDeleteLead = true

				if(leads.length > 0){

					leads.forEach(function(item) {
						let url   = 'leads/delete_lead/' + item
						let method= 'DELETE'
						let data  = {provider : "delete leads", permissionid : 19}
						
						that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
							if ( response.data.success ) {
								that.notifyMe('Lead Deleted !', 'success')
								that.loadingDeleteLead = false
								that.leftSidebarInfo()
								that.$bvModal.hide('modal-delete-leads')
								that.getLeads(true, 'filter')
								
							}
						}).catch(( {response} ) => {
							that.loadingDeleteLead = false
							that.notifyMe(response.data.message, 'warn')
						})
					})
				} else {
					
				
					let url   = 'leads/delete_lead/' + that.selectedLead.entry.id
					let method= 'DELETE'
					let data  = {provider : "delete leads", permissionid : 19}
					
					that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
						if ( response.data.success ) {
							that.notifyMe('Lead Deleted !', 'success')
							that.loadingDeleteLead = false
							that.leftSidebarInfo()
							that.$bvModal.hide('bv-modal-leadDetails')
							that.$bvModal.hide('modal-delete-lead')
							that.getLeads(true, 'filter')
							
						}
					}).catch(( {response} ) => {
						// console.log(response)
						that.loadingDeleteLead = false
						that.notifyMe(response.data.message, 'warn')
					})
				}
				
			},
			switchLeads(action) {
				let that = this
				if( action == 'next' ) {
					if( that.gridData.length < that.selectedLead.id + 5 && !that.loadingData ) {
						that.getLeads(true,'')
					}
					that.selectedLead.id    = that.selectedLead.id + 1

					// to Centralize alter
					that.countNewField = 0;
					that.gridData[that.selectedLead.id].entry.forEach(function(item) {
						if(item.type && item.type == 'new') {
							that.countNewField = that.countNewField + 1;
						}
					});
					
					// to Centralize alter
				} else {
					that.selectedLead.id = that.selectedLead.id - 1

					// to Centralize alter
					that.countNewField = 0;
					console.log(that.gridData[that.selectedLead.id]);
					that.gridData[that.selectedLead.id].entry.forEach(function(item) {
						if(item.type && item.type == 'new') {
							that.countNewField = that.countNewField + 1;
						}
					});
					// to Centralize alter
					
					
				}	

				/* Lead duplicate */
				let url    = 'lead_details'
				let method = 'GET'
				let data   = {'lead_id':that.gridData[that.selectedLead.id].id}
				that.duplicates_leads = [];
				that.$store.dispatch('apiRequest', {url, method, data }).then((response) => {
					if(response.data.success == true) {
						var duplicatesLeads = response.data.duplicatesLeads;
						duplicatesLeads.forEach( function(duplicates_lead) {
							let duplicateLeadEntry = Object.keys( duplicates_lead.entry ), arrayDLEntry = [], entryDLValues = Object.values( duplicates_lead.entry )
							if ( duplicateLeadEntry.length > 0 ) {
								duplicateLeadEntry.forEach( function(item, key) {
									arrayDLEntry.push( { name: item, value: entryDLValues[key], fieldSelected: "custom_field" } )
								});
							}
							var date1 = new Date()
							var date = new Date(duplicates_lead.created_at)
							var duration_2 = that.duration(date1,date)
							that.duplicates_leads.push({
								id : duplicates_lead.id,
								full_name: (duplicates_lead.full_name == null || duplicates_lead.full_name == '') ? duplicates_lead.personal_first_name + ' ' + duplicates_lead.personal_last_name : duplicates_lead.full_name,
								personal_first_name		: duplicates_lead.personal_first_name == null ? '' : duplicates_lead.personal_first_name,
								personal_last_name		: duplicates_lead.personal_last_name == null ? '' : duplicates_lead.personal_last_name,
								status					: duplicates_lead.param.state,
								score					: duplicates_lead.scoring,
								email					: duplicates_lead.email == null ? '' : duplicates_lead.email,
								personal_phone			: duplicates_lead.personal_phone == null ? '' : duplicates_lead.personal_phone,
								generatedOn				: duplicates_lead.created_at,
								updated_at				: duplicates_lead.updated_at,
								source					: duplicates_lead.source.name,
								color					: duplicates_lead.param.color,
								statusId				: duplicates_lead.param.id,
								historic				: duplicates_lead.historiques,
								notes					: duplicates_lead.notes,
								email_verified			: duplicates_lead.email_verified,
								phone_verified			: duplicates_lead.phone_verified,
								enabled					: duplicates_lead.enabled,
								is_duplicate	        : duplicates_lead.is_duplicate,
								entry					: arrayDLEntry,
								duration				: duration_2,
								details					: '',
							})
						});
					}

					var result = that.duplicates_leads;
					if(result.length > 0) {
						that.setCollapseParams();
						that.selectedLead.entry = that.gridData[that.selectedLead.id];
						that.selectedLead.entry.duplicates_leads = result;
						that.leadEnrichmensList = [];
						that.myStatus.forEach( function(item) {
							if ( item.id == that.selectedLead.entry.statusId  ) {
								that.statusStyle.backgroundColor = item.color;
								that.statusStyle.borderColor	 = item.color;
							}
						})
					} else {
						that.setDefaultCollapseParams();
						that.selectedLead.entry = that.gridData[that.selectedLead.id];
						that.selectedLead.entry.duplicates_leads = result;
						that.leadEnrichmensList = [];
						that.myStatus.forEach( function(item) {
							if ( item.id == that.selectedLead.entry.statusId  ) {
								that.statusStyle.backgroundColor = item.color;
								that.statusStyle.borderColor	 = item.color;
							}
						})
					}

					let ownerForeach = ''
					that.owners.forEach( function(owner) {
						if( that.selectedLead.entry.assigned_user_id == owner.id ) {
							ownerForeach = owner
						}else if(that.selectedLead.entry.assigned_user_id == null){
							ownerForeach = ''
						}
					})
					that.selectedOwner = ownerForeach
					

				}).catch(() => {
									
				});
				/* Lead duplicate */

				

				//setTimeout(function() {
				
				//}, 0);
			},
			getUserInfo(action = '') {
				let that = this
				this.optionsSelectSource.defaultSelected = ''
				let url   = 'get_user_info'
				let method= 'GET'
				let data  = {}
				that.loadingData = true

				that.$store.dispatch( 'apiRequest', { url, method, data } ).then((response) => {
					
					that.loadingData = false
					that.myStatus = []

					that.smtp = response.data.smtp;

					if( that.gridColumns.length == 9 ) {
						response.data.lead_columns.forEach( function(item) {
							if( item.type == "custom" ) {
								that.gridColumns.push({ title: item.libelle, column: item.type + '_' + item.libelle, enable: false })
							}
						} )
						that.gridColumns.push({ title: this.$t('Details'), column: 'details', enable: true })
					}
					
					let SelectedCols = localStorage.getItem('selected-cols')
					if( SelectedCols != null ) {
						SelectedCols = JSON.parse(SelectedCols)
						that.gridColumns.forEach( function( item ) {
							item.enable = SelectedCols.includes(item.column)
						} )
					} else {
						SelectedCols = []
						that.gridColumns.forEach( function( item ) {
							if( item.enable ) {
								SelectedCols.push(item.column)
							}
						} )
						localStorage.setItem('selected-cols', JSON.stringify(SelectedCols) )
					}
								
					if ( action == 'filter' ) {
						let countSt = that.tableSearch.selectedStatus.length
						that.tableSearch.selectedStatus.splice(0, countSt)
					} else {
						that.emailArray.arrayEmailTemplates = []
						that.mySources = []
					}

					let mystatus = response.data.status
					that.allStatus = [];
					mystatus.forEach( function(entry) {
						that.allStatus.push({ id: entry.id, title: entry.state, option: entry.state, color: entry.color })
						if( entry.status == 1 ) {
							that.myStatus.push({ id: entry.id, title: entry.state, option: entry.state, color: entry.color })
						}
					} );
					
					if( that.tableSearch.selectedStatus.length == 0 ) {
						mystatus.forEach( function(entry) {
							if( entry.defaut == 1 ) {
								that.tableSearch.selectedStatus.push(entry.id)
							}
						} );
					} else {
						var selectedStatus = []
						that.tableSearch.selectedStatus.forEach( function (status) {
							selectedStatus.push(status)
						} )
						that.tableSearch.selectedStatus.splice(0, selectedStatus.length)
						selectedStatus.forEach( function(status) {
							that.tableSearch.selectedStatus.push(status)
						} )
					}

					if ( action != 'filter' ) {
						response.data.emailsTemplates.forEach( function(model) {
							that.emailArray.arrayEmailTemplates.push({id: model.id, title: model.title, sujet: model.subject, body: model.body})
						} );

						let selectSource = true, SourceCount = that.tableSearch.selectedSources.length
						let MySelectedSource = []
						
						that.tableSearch.selectedSources.forEach( function(source) {
							MySelectedSource.push(source)
						} )
						that.tableSearch.selectedSources.splice(0, SourceCount)
						
						response.data.sources.forEach( function(source) {
							that.mySources.push({ id: source.id, title: source.name, color: "entry.color" })
							
							if ( that.$route.params.source == source.id && SourceCount == 0 ) {
								selectSource = false
								that.tableSearch.selectedSources.push(source.id)
							}
						});
						
						if( MySelectedSource > 0 ) {
							MySelectedSource.forEach( function(source) {
								that.tableSearch.selectedSources.push(source)
							} )
						}

						
						
						if( (selectSource && SourceCount == 0) || MySelectedSource.length == that.mySources.length ) {
							this.optionsSelectSource.defaultSelected = 'All'
						} else {
							that.getLeads(true, action)
						}
					} else {
						that.getLeads(true, action)
					}

					let urlOwners   = '/teammember/teamMembers'
					let methodOwners= 'GET'
					let dataOwners  = {}
					that.loadingData = true
					that.$store.dispatch( 'apiRequest', { urlOwners, methodOwners, dataOwners } ).then((resp) => {
						that.owners = resp.data.members;
						console.log('owners :' + that.owners)

						that.owners.forEach( function(item) {
							that.tableSearch.assigned_user_id.push(item.id)
						})
					})
				}).catch(( {response} ) => {
					console.log(response)
					that.loadingData = false
					that.processing = false
				})

				
			},
			duration(date1,date){
				let that = this
				var diff = Math.floor(date1.getTime() - date.getTime());
				var day = 1000 * 60 * 60 * 24;
				var days = Math.floor(diff/day);
				var months = Math.floor(days/31);
				var years = Math.floor(months/12);
				let message = '';
				if( years == 0 && months == 0 ) {
					if( days > 0 ) {
						message = days + that.$t("DaysAgo"); 
					} else {
						if( date1.getHours() - date.getHours() > 3 ) {
							message = that.$t("Today"); 
						} else {
							message =  that.$t("New"); 
						}
					}
				} else if ( years == 0 && months > 0 ) {
					if ( months == 1 ) {
						message = months + that.$t("MonthAgo")
					} else {
						message = months + that.$t("MonthsAgo")
					}
				} else if ( years > 0 ) {
					if ( years == 1 ) {
						message = years + that.$t("YearAgo")
					} else {
						message = years + that.$t("YearsAgo")
					}
				}
				return message;
			},
			getLeads(bottomOfWindow, action = '') {

				let that = this

				let leadGetter = that.leadGetter
				
				if ( that.gridData.length == that.totalLead && that.totalLead != 0 ) {
					that.moreData = true
				} else {
					that.moreData = false
				}
				
				if ( action == 'filter' ) {
					that.current_page = 1
					leadGetter = true
					that.searchCount += 1;
				}

				//if(Object.keys(that.$store.state.smartViewsItem).length == 0){
					if ( action == 'filter' && that.filterChanged == true) {
						that.showSaveView = true
					}
				//}
				
				
				if (that.tableSearch.selectedSources.length == 0 && that.tableSearch.selectedStatus.length == 0) {
					return false
				}

				let selectedFilterAll = []
				if(that.tableSearch.selectedFilter.length == that.Filters.length){
					selectedFilterAll.push('all')
				}else{
					selectedFilterAll = that.tableSearch.selectedFilter
				}
				
				if( bottomOfWindow && that.last_page >= that.current_page && leadGetter ) {
					
					that.leadGetter  = false
					that.loadingData = true

					
					
					let url   = 'myLeads?page=' + that.current_page
					let method= 'POST'
					let data  = { searchQuery:that.tableSearch.searchQuery, selectedSources:that.tableSearch.selectedSources, selectedStatus:that.tableSearch.selectedStatus, dateRange:that.dateRange, selectedFilter:selectedFilterAll, assigned_user_id:that.tableSearch.assigned_user_id}
					
					that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
						
						if ( action == 'filter' ) {
							that.gridData = []
							that.searchCount -= 1;
						}
						let myLeads = response.data.myLeads.data
						
						let DemoData = localStorage.getItem('DemoData')
						if ( that.DemoDataPanel && DemoData == 'first' ) {
							localStorage.removeItem('DemoData')
							DemoData = true
						} else {
							DemoData = false
						}
						myLeads.forEach( function(lead) {
							let entryLeads = Object.keys( lead.entry ), arrayEntry = [], entryValues = Object.values( lead.entry )
							if ( entryLeads.length > 0 ) {
								entryLeads.forEach( function(item, key) {
									arrayEntry.push( { name: item, value: entryValues[key], fieldSelected: "custom_field" } )
								} );
							}
							var date1 = new Date()
							var date2 = new Date(lead.created_at)
							var duration = that.duration(date1,date2)
							that.gridData.push(
								{ 
									id				: lead.id,
									full_name		: (lead.full_name == null || lead.full_name == '') ? lead.personal_first_name + ' ' + lead.personal_last_name : lead.full_name,
									personal_first_name: lead.personal_first_name == null ? '' : lead.personal_first_name,
									personal_last_name: lead.personal_last_name == null ? '' : lead.personal_last_name,
									status			: lead.param.state,
									score			: lead.scoring,
									email			: lead.email == null ? '' : lead.email,
									personal_phone	: lead.personal_phone == null ? '' : lead.personal_phone,
									generatedOn		: lead.created_at,
									updated_at		: lead.updated_at,
									source			: lead.source.name/* lead.source_id */,
									color			: lead.param.color,
									statusId		: lead.param.id,
									historic		: lead.historiques,
									notes			: lead.notes,
									duplicates_leads: [],
									email_verified	: lead.email_verified,
									phone_verified	: lead.phone_verified,
									enabled			: lead.enabled,
									entry			: arrayEntry,
									duration		: duration,
									details			: '',
									is_duplicate    :lead.is_duplicate,
									assigned_action	: lead.assigned_action,
									assigned_user_id	: lead.assigned_user_id,
									email_historiques   : lead.email_historiques,
								}
							)

							if( lead.enabled == 0 ) {
								that.ColumnBlur++
							}
										
							entryLeads.forEach( function(item) {
								that.gridColumns.forEach( function(col) {
									if( col.title == item ) {
										that.gridData[that.gridData.length - 1][col.column] = lead.entry[item] // id
									}
								} )
							} )
						} );
						
						if ( that.gridData.length == that.totalLead ) {
							that.moreData = true
						} else {
							that.moreData = false
						}
						/* if ( that.last_page == that.current_page ) {
							that.moreData = true
						} */
						
						that.last_page = response.data.myLeads.last_page
						that.current_page += 1
						// if( that.last_page > that.current_page ) {
						// }
						that.totalLead = response.data.myLeads.total
						
						that.leadGetter = true
						
						if (that.searchCount < 1) {
							that.loadingData = false
							this.processing = false
						}
						
					}).catch(( {response} ) => {
						console.log(response)
						that.loadingData = false
						this.processing = false
					})
					
				}
			},
			smartViewsFilter() {
				let that = this;
				that.current_page = 1
				that.gridData = []
				var filter = JSON.parse(that.smartViewsItem.filter);
				var dateRange = (typeof filter.dateRange == 'string') ? JSON.parse(filter.dateRange) : filter.dateRange;
				that.tableSearch.searchQuery = (filter.searchQuery) ? filter.searchQuery : '';
				if(filter.selectedStatus.length > 0) {
					var selectedStatus = []
					that.tableSearch.selectedStatus.forEach( function (status) {
						selectedStatus.push(status)
					} )
					that.tableSearch.selectedStatus.splice(0, selectedStatus.length)
					filter.selectedStatus.forEach( function(statusItem) {
						that.tableSearch.selectedStatus.push(statusItem)
					})
				}
				if(filter.selectedSources.length > 0) {
					var selectedSources = []
					that.tableSearch.selectedSources.forEach( function (source) {
						selectedSources.push(source)
					} )
					that.tableSearch.selectedSources.splice(0, selectedSources.length)
					filter.selectedSources.forEach( function(sourceItem) {
						that.tableSearch.selectedSources.push(sourceItem)
					})
				}
				if(filter.selectedFilter.length > 0) {
					var selectedFilter = []
					that.tableSearch.selectedFilter.forEach( function (filter) {
						selectedFilter.push(filter)
					} )
					that.tableSearch.selectedFilter.splice(0, selectedFilter.length)
					filter.selectedFilter.forEach( function(filterItem) {
						that.tableSearch.selectedFilter.push(filterItem)
					})
				}

				if(Object.keys(dateRange).length != 0) {
					that.dateRange = dateRange;
				} else {
					that.dateRange = {};
				}
			},
			changeTemplate() {
				let that = this;
				console.log(that.emailArray.arrayEmailTemplates[this.emailArray.selectedTemplate])
				this.emailArray.Subject = (that.emailArray.selectedTemplate !== '') ? that.emailArray.arrayEmailTemplates[that.emailArray.selectedTemplate].sujet : ''
				this.emailArray.contentMessage = (that.emailArray.selectedTemplate !== '') ? that.emailArray.arrayEmailTemplates[that.emailArray.selectedTemplate].body : ''
				// this.emailArray.selectedTemplate = 
			},
			
			discardEmail() {
				this.emailArray.Subject = ''
				this.emailArray.contentMessage = ''
			},
			
			sendEmail() {
				let that   = this;
				that.emailArray.to = that.selectedLead.entry.email
				let url    = 'fiche_lead/send_email';
				let method = 'POST';
				let data   = {from:that.emailArray.from,to:that.emailArray.to,subject:that.emailArray.Subject,body:that.emailArray.contentMessage,leadId:that.selectedLead.entry.id};
				that.$store.dispatch('apiRequest', {url,method,data}).then((response) => {
					let message = response.data.message;
					if(response.data.success) {
						that.emailArray.Subject = ''
						that.emailArray.contentMessage = ''
						that.emailArray.selectedTemplate = ''
						//that.gridData[that.selectedLead.id].notes = response.data.notes
						that.selectedLead.entry.notes = response.data.notes
						that.selectedLead.entry.email_historiques = response.data.email_historiques
						that.notifyMe(message, 'success')
					}
				}).catch(( {response} ) => {
					that.processing = false
					that.notifyMe(response.data.message, 'warn')
				})
			},
			
			addNewLeads() {
				let that = this
								
				if ( Object.values(that.validateField).indexOf(true) == -1 ) {
					
					that.processing = true
					
					let url   = 'leads/add_new_lead'
					let method= 'POST'
					let data  = { arrayLead: that.newLead, customField: that.arrayFieldsAddLead, provider : "add leads" , permissionid : 11}
					
					that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
						// let message = response.data.message
						if ( response.data.success ) {
							
							if ( response.data.sources.length > 0 ) {
								that.tableSearch.selectedSources = []
								that.mySources = []
								response.data.sources.forEach( function(source) {
									that.mySources.push({ id: source.id, title: source.name, color: "entry.color" })
								} );
							} else {
								that.getLeads(true, 'filter')
							}
							
							that.newLead.full_name = ''
							that.newLead.personal_first_name = ''
							that.newLead.personal_last_name = ''
							that.newLead.personal_phone = ''
							that.newLead.email = ''
							that.newLead.status = ''
							that.arrayFieldsAddLead = []
							that.leftSidebarInfo()
							that.notifyMe(this.$t('ChangesSaved'), 'success')
							that.$bvModal.hide('bv-modal-addLead')
							
						} else {
							let varKey  = Object.keys( response.data.message ) //, valuesObj = Object.values( response.data.message );
							var message = ''
							let objMsg  = response.data.message
							varKey.forEach( function(item) {
								message += objMsg[item] + "<br>"
							} )
							that.notifyMe(message, 'warn')
						}
						that.processing = false
					}).catch(( {response} ) => {
						that.processing = false
						that.notifyMe(response.data.message, 'warn')
					})
					
				} else {
					that.notifyMe('Field required !', 'warn')
				}
				
			},
			connectEnrichment() {
				let that = this, search = ''
				that.dataEnrichmens.loading = true
				// that.selectedLead.entry.email_verified == 1
				if( that.selectedLead.entry.email != '' && that.selectedLead.entry.email_verified == 1 ) {
					search = that.selectedLead.entry.email
				} else if( that.selectedLead.entry.personal_phone != '' && that.selectedLead.entry.phone_verified == 1 ) {
					search = that.selectedLead.entry.email != '' ? that.selectedLead.entry.personal_phone : that.selectedLead.entry.email
				}
				if( search != '' ) {					
					let url   = 'enrichment/search'
					let method= 'POST'
					let data  = {search:search}
					that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
						that.dataEnrichmens.loading = false
						if( response.data.success ) {
							if( Object.keys(response.data.result).length > 0 ) {
								that.leadEnrichmensList = response.data.result
							} else {
								that.notifyMe("Data not found !", 'warn')
							}
						} else {
							that.notifyMe(response.data.message, 'warn')
						}
					}).catch(( {response} ) => {
						that.dataEnrichmens.loading = false
						that.notifyMe(response.data.message, 'warn')
					})
				} else {
					that.notifyMe('Email or phone must be valid !', 'warn')
					that.dataEnrichmens.loading = false
				}
			},
			
			listLeadEnrichment() {
				
				let that = this
				that.dataEnrichmens.status = false
				
				let url   = 'enrichment/connected_list'
				let method= 'GET'
				let data  = {provider : "enrich leads", permissionid : 16}
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					if( response.data.enrichmens.length > 0 ) {
						that.dataEnrichmens.name = response.data.enrichmens[0].provider
						that.dataEnrichmens.status = true
					}
				}).catch(( {response} ) => {
					console.log(response)
				})
				
			},
			//duplicates(id) {
				//let that = this
				//let url   = 'lead/duplicates'
				//let data  = {id:id}
				//console.log(id)
				//that.$store.dispatch('lead_details', { url, data } ).then((response) => {
					//console.log(response)
				//}).catch(( {response} ) => {
					//console.log(response)
				//})
			//},
			actionClick( itemIdsArray = "", action = "" ) {
				let that = this
				that.url = this.$store.state.calendly_url;



				if( action == 'itemIdsArray' ) {
					that.itemIds = itemIdsArray
					if (this.$store.state.calendly_url) {
						const myurl = new URL(this.$store.state.calendly_url);
						myurl.searchParams.set('name', itemIdsArray.full_name?itemIdsArray.full_name:itemIdsArray.personal_first_name+' '+itemIdsArray.personal_last_name);
						myurl.searchParams.set('email', itemIdsArray.email);
						that.url = myurl.href;
					}

				} else if(that.selectedLead.entry.enabled == 1) {
					if (this.$store.state.calendly_url) {
						const myurl = new URL(this.$store.state.calendly_url);
						myurl.searchParams.set('name', that.selectedLead.entry.full_name?that.selectedLead.entry.full_name:that.selectedLead.entry.personal_first_name+' '+that.selectedLead.entry.personal_last_name);
						myurl.searchParams.set('email', that.selectedLead.entry.email);
						that.url = myurl.href;
					}

					that.owners.forEach( function(owner) {
						if( that.selectedLead.entry.assigned_user_id == owner.id ) {
							that.selectedOwner = owner
						}
					})

					/* Lead duplicate */
					that.leadDetails(that.selectedLead.entry.id,that.selectedLead.entry);
					that.selectedLead.entry.duplicates_leads = that.duplicates_leads;
					/* Lead duplicate */
					//that.$bvModal.show('bv-modal-leadDetails')
					that.listLeadEnrichment()
					that.myStatus.forEach( function(item) {
						if ( item.id == that.selectedLead.entry.statusId  ) {
							that.statusStyle.backgroundColor = item.color
							that.statusStyle.borderColor	 = item.color
						}
					} )	
					if( action == 'sendEmail' ) {
						that.tabsFocus = false
					} else {
						that.tabsFocus = true
					}
				}
			},
			leftSidebarInfo() {
				let that = this
				let url   = 'left_sidebar'
				let method= 'GET'
				let data  = {}
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					console.log(response)
					that.$store.dispatch('GetClients')
				}).catch(( {response} ) => {
					console.log(response)
				})
			},
			
			scroll() {
				let that = this
				window.onscroll = () => {
					// let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
					let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight + 5 >= document.documentElement.offsetHeight;
					if (bottomOfWindow) {
						that.getLeads(bottomOfWindow, '')
					}
				};
				// let that = this
				/* window.onscroll = () => {
					let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
					if( that.current_page != 1 ) {
						that.getLeads(bottomOfWindow, '')
					}
				}; */
			},
			
			arcMove(lineCap, Number) {
				let percent = ( Number / 10) * 100
				var can 		= document.getElementById('canvas'),
					spanProcent = document.getElementById('procent'),
					c 			= can.getContext('2d');
				var posX 		= can.width / 2,
					posY 		= can.height / 2,
					fps 		= 1000 / 200,
					// procent 	= 0,
					oneProcent 	= 360 / 100,
					result 		= oneProcent * percent;
				
				c.lineCap = lineCap;
				
				
				var deegres = 0;
				clearInterval(acrInterval);
				var acrInterval = setInterval (function() {
					deegres += 1;
					c.clearRect( 0, 0, can.width, can.height );
					// procent = deegres / oneProcent;
					
					spanProcent.innerHTML = '<div><span>' + Number + '</span>/10' + '</div>';
					
					c.beginPath();
					c.arc( posX, posY, 100, (Math.PI/180) * 270, (Math.PI/180) * (270 + 360) );
					c.strokeStyle = '#dddddd';
					c.lineWidth = '6';
					c.stroke();
					
					c.beginPath();
					c.strokeStyle = '#00BBF6';
					c.lineWidth = '6';
					c.arc( posX, posY, 100, (Math.PI/180) * 270, (Math.PI/180) * (270 + deegres) );
					c.stroke();
					if( deegres >= result ) clearInterval(acrInterval);
				}, fps);
			},
			getDefaultStatus() {
				let that = this
				that.myStatus.forEach( function(item) {
					if(item.title == 'Pending' || item.title == 'En attente') {
						that.newLead.status = item.id
					}
				});
			},
			myLeadDetail: function(entry,id=0,action='') {
				let that   = this
				let url    = 'lead_details'
				let method = 'GET'
				let data   = {'lead_id':entry.id}
				that.duplicates_leads = [];
				that.$store.dispatch('apiRequest', {url, method, data }).then((response) => {
					if(response.data.success == true) {
						var duplicatesLeads = response.data.duplicatesLeads;
						duplicatesLeads.forEach( function(duplicates_lead) {
							let duplicateLeadEntry = Object.keys( duplicates_lead.entry ), arrayDLEntry = [], entryDLValues = Object.values( duplicates_lead.entry )
							if ( duplicateLeadEntry.length > 0 ) {
								duplicateLeadEntry.forEach( function(item, key) {
									arrayDLEntry.push( { name: item, value: entryDLValues[key], fieldSelected: "custom_field" } )
								});
							}
							var date1 = new Date()
							var date = new Date(duplicates_lead.created_at)
							var duration_2 = that.duration(date1,date)
							that.duplicates_leads.push({
								id : duplicates_lead.id,
								full_name: (duplicates_lead.full_name == null || duplicates_lead.full_name == '') ? duplicates_lead.personal_first_name + ' ' + duplicates_lead.personal_last_name : duplicates_lead.full_name,
								personal_first_name		: duplicates_lead.personal_first_name == null ? '' : duplicates_lead.personal_first_name,
								personal_last_name		: duplicates_lead.personal_last_name == null ? '' : duplicates_lead.personal_last_name,
								status					: duplicates_lead.param.state,
								score					: duplicates_lead.scoring,
								email					: duplicates_lead.email == null ? '' : duplicates_lead.email,
								personal_phone			: duplicates_lead.personal_phone == null ? '' : duplicates_lead.personal_phone,
								generatedOn				: duplicates_lead.created_at,
								updated_at				: duplicates_lead.updated_at,
								source					: duplicates_lead.source.name,
								color					: duplicates_lead.param.color,
								statusId				: duplicates_lead.param.id,
								historic				: duplicates_lead.historiques,
								notes					: duplicates_lead.notes,
								email_verified			: duplicates_lead.email_verified,
								phone_verified			: duplicates_lead.phone_verified,
								enabled					: duplicates_lead.enabled,
								is_duplicate	        : duplicates_lead.is_duplicate,
								entry					: arrayDLEntry,
								duration				: duration_2,
								details					: '',
							})
						});
						if(action == 'view') {
							that.actionLead(entry,id,action,that.duplicates_leads);
						}
					}
				}).catch(() => {
									
				});
				return  that.duplicates_leads;
			},
			toggleModal: function() {
				let that = this;
				that.closeModalLeadDetails();
			},
			actionLead: function(entry, id, action,result) {
				if(action == 'view') {		
					let that = this;
					if(result.length > 0) {
						that.setCollapseParams();
						that.selectedLead.id = id;
						that.selectedLead.entry = entry;
						that.selectedLead.entry.duplicates_leads = result;
						that.allStatus.forEach( function(item) {
							if ( item.id == that.selectedLead.entry.statusId  ) {
								that.statusStyle.backgroundColor = item.color;
								that.statusStyle.borderColor	 = item.color;
							}
						})
					}  else {
						that.selectedLead.id = id;
						that.selectedLead.entry = entry;
						that.selectedLead.entry.duplicates_leads = result;
						that.allStatus.forEach( function(item) {
							if ( item.id == that.selectedLead.entry.statusId  ) {
								that.statusStyle.backgroundColor = item.color;
								that.statusStyle.borderColor	 = item.color;
							}
						})
					}
				}
			},
			markAsADuplicate: function(entry) {
				let that = this
				function estDuplicate(monStatus) {
					return (monStatus.title == 'Duplicate' || monStatus.title == 'Doublon')  ? monStatus.title : '';
				}
				var statusId = that.allStatus.find(estDuplicate).id;
				if(statusId != entry.statusId) {
					that.allStatus.forEach( function(item) {
						if ( item.id == statusId ) {
							that.statusStyle.backgroundColor = item.color
							that.statusStyle.borderColor	 = item.color
							entry.status = item.title
							that.gridData[ that.selectedLead.id ].status = item.title
							that.gridData[ that.selectedLead.id ].color  = item.color
							that.selectedLead.entry.statusId = statusId
						}
					})
					let url   = 'leads/change_status'
					let method= 'PATCH'
					let data  = {leadId: entry.id, newStatus: statusId, provider : "edit status"}
					that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
						if( response.data.success ) {
							if( response.data.message) {
								let myMessage = response.data.message
								that.notifyMe(myMessage,'success')
							}
							if( response.data.sent ) {
								let message = 'Lead has been sent'
								that.notifyMe(message, 'success')
							} //else {
								//that.notifyMe('Status Changed !', 'success')
							//}
							that.gridData[ that.selectedLead.id ].historic = response.data.historiques
							that.selectedLead.entry.historic = response.data.historiques
							that.gridData[ that.selectedLead.id ].notes = response.data.notes
							that.selectedLead.entry.notes = response.data.notes
						}
					}).catch(( {response} ) => {
						console.log(response)
					})
				}
			},
			setDefaultCollapseParams: function() {
				let that = this;
				that.visible_collapse_1 = true;
				that.visible_collapse_2 = false;
			},
			setCollapseParams: function() {
				let that = this;
				that.visible_collapse_1 = false;
				that.visible_collapse_2 = true;
			},
			leadDetails: function(leadId,entry) {
				let that   = this
				let url    = 'lead_details'
				let method = 'GET'
				let data   = {'lead_id':leadId}
				that.duplicates_leads = [];
				console.log(entry)
				that.$store.dispatch( 'apiRequest', { url, method, data }).then((response) => {
					that.visible_collapse_1 = true
					that.visible_collapse_2 = false
					if(response.data.success == true) {
						if(response.data.duplicatesLeads.length > 0) {
							var duplicatesLeads = response.data.duplicatesLeads;
							duplicatesLeads.forEach( function(duplicates_lead) {
								let duplicateLeadEntry = Object.keys( duplicates_lead.entry ), arrayDLEntry = [], entryDLValues = Object.values( duplicates_lead.entry )
								if ( duplicateLeadEntry.length > 0 ) {
									duplicateLeadEntry.forEach( function(item, key) {
										arrayDLEntry.push( { name: item, value: entryDLValues[key], fieldSelected: "custom_field" } )
									});
								}
								var date1 = new Date()
								var date = new Date(duplicates_lead.created_at)
								var duration_2 = that.duration(date1,date)
								that.duplicates_leads.push({
									id : duplicates_lead.id,
									full_name: (duplicates_lead.full_name == null || duplicates_lead.full_name == '') ? duplicates_lead.personal_first_name + ' ' + duplicates_lead.personal_last_name : duplicates_lead.full_name,
									personal_first_name		: duplicates_lead.personal_first_name == null ? '' : duplicates_lead.personal_first_name,
									personal_last_name		: duplicates_lead.personal_last_name == null ? '' : duplicates_lead.personal_last_name,
									status					: duplicates_lead.param.state,
									score					: duplicates_lead.scoring,
									email					: duplicates_lead.email == null ? '' : duplicates_lead.email,
									personal_phone			: duplicates_lead.personal_phone == null ? '' : duplicates_lead.personal_phone,
									generatedOn				: duplicates_lead.created_at,
									updated_at				: duplicates_lead.updated_at,
									source					: duplicates_lead.source.name,
									color					: duplicates_lead.param.color,
									statusId				: duplicates_lead.param.id,
									historic				: duplicates_lead.historiques,
									notes					: duplicates_lead.notes,
									email_verified			: duplicates_lead.email_verified,
									phone_verified			: duplicates_lead.phone_verified,
									enabled					: duplicates_lead.enabled,
									is_duplicate	        : duplicates_lead.is_duplicate,
									entry					: arrayDLEntry,
									duration				: duration_2,
									details					: '',
								})
							})
							that.setCollapseParams();
						}
					}
				}).catch(() => {
						
				})
			},
			set_email_data: function () {
				let that = this;	
				let url    = 'get_actif_smtp';
				let method = 'GET';
				let data = {};
				that.$store.dispatch('apiRequest', {url, method,data}).then((response) => {
					if(response.data.success){
						let smtp = response.data.smtp;
						if(smtp) {
							that.emailArray.from = smtp.email;
						} else {
							var UserCheck = JSON.parse(localStorage.getItem('oml-user'))
							that.emailArray.from = UserCheck.email;
						}
					}
				});
			},
			adjustLeadInfoEditorDropdown: function () {
				var element = document.getElementsByClassName("ql-selected");
				if(element.length > 0) {
					element[0].classList.remove("ql-selected");
				}
				let placeholderPickerItems = Array.prototype.slice.call(document.querySelectorAll('.ql-placeholder .ql-picker-item'));
				placeholderPickerItems.forEach(item => item.textContent = item.dataset.value);
				const label = document.querySelector('.ql-placeholder .ql-picker-label');
				label.innerHTML = '';	
				label.innerHTML = 'Lead infos <svg viewBox="0 0 18 18" style="margin-top: -11px;"> <polygon class="ql-stroke" points="7 11 9 13 11 11 7 11"></polygon> <polygon class="ql-stroke" points="7 7 9 5 11 7 7 7"></polygon> </svg>';
			},
			test: function(anglet){
				let that = this;
				if(anglet == 'send_email') {
					that.anglet_value = 'send_email';
					that.adjustLeadInfoEditorDropdown();
				} else {
					that.anglet_value = 'lead_detail';
				}
			},
			addSmartView(){
				let that = this;
				console.log(that)
				that.$bvModal.show('add_view');
			},
			closeAddSmartViewModal(){
				let that = this;
				that.smartview.id=0;
				that.smartview.name='';
			},
			saveSmartView(){
				let that = this;
				console.log(typeof that.tableSearch);
				console.log(that.dateRange);
				console.log(that.smartview.name);
				if(that.smartview.name != ""){
					let url   = 'save_filters';
					let method= 'POST';
					let data  = {name:that.smartview.name,searchQuery:that.tableSearch.searchQuery, selectedSources:that.tableSearch.selectedSources, selectedStatus:that.tableSearch.selectedStatus, dateRange:that.dateRange, selectedFilter:that.tableSearch.selectedFilter };
					that.$store.dispatch('apiRequest', { url, method, data }).then((response) => {
						console.log(response.data.success);
						if(response.data.success){
							that.$store.state.reloadLeftSidebarCompenent = Math.random();
							that.$store.state.checkChange = true;
							that.closeAddSmartViewModal();
							that.$bvModal.hide('add_view');
							let message = 'Your smart view sent'
							that.notifyMe(message, 'success')
						}
					}).catch(({response}) => {	
						console.log(response);
					})
				}else{
					let messageError = 'You must enter a name'
					that.notifyMe(messageError, 'warn')
				}
				
			},
			bulkActionSelectWorkspace: function(workSpace_id,lead_id){
				this.bulkSendTo.client_id = this.$store.state.user.id
				this.bulkSendTo.worspace_id = workSpace_id

				if(lead_id == '' || lead_id == null){
					this.bulkSendTo.lead_id = this.itemIds
				}else{
					this.bulkSendTo.lead_id = []
					this.bulkSendTo.lead_id[0] = lead_id
				}
			},
			bulkActionSend: function(){
				let that = this;
				let url   = '/leads/sendToWorkspace';
				let method= 'POST';
				let data  = that.bulkSendTo
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					console.log(response)
                    that.getLeads(true, 'filter')
					this.$refs.dropdownBulk.hide(true)
					this.$refs.dropdownFicheLead.hide(true)
					that.notifyMe("The lead sent to workspace", 'success')

				}).catch(( {response} ) => {
					console.log(response)
					that.notifyMe(response.data.message, 'warn');
				})
			},
			bulkOwnerSelectWorkspace: function(owner_id){
				this.bulkOwners.client_id = this.$store.state.user.id
				this.bulkOwners.assignedTo = owner_id
				this.bulkOwners.leads = this.itemIds
			},
			bulkOwnerSend: function(){
				if(this.bulkOwners.assignedTo == '' || this.bulkOwners.assignedTo == null){
					this.notifyMe('You must choose a member', 'warn');
				}else{
					let that = this;
					let url   = '/manage/assigne';
					let method= 'POST';
					let data  = that.bulkOwners
					
					that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
						console.log(response)
						that.getLeads(true, 'filter')
						this.$refs.dropdownBulk.hide(true)
						that.notifyMe("The lead assigned to member", 'success')
					}).catch(( {response} ) => {
						console.log(response)
						that.notifyMe(response.data.message, 'warn');
					})
				}
			},
			sendOneOwnerSelected: function(lead_id){
				let that = this;
				this.bulkOwners.client_id = this.$store.state.user.id
				this.bulkOwners.assignedTo = this.selectedOwner.id
				this.bulkOwners.leads[0] = lead_id

				let url   = '/manage/assigne';
				let method= 'POST';
				let data  = that.bulkOwners
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					console.log(response)
					that.getLeads(true, 'filter')
					that.notifyMe("The lead assigned to member", 'success')
					
					this.$refs.dropdown.hide(true)
				}).catch(( {response} ) => {
					console.log(response)
					that.notifyMe(response.data.message, 'warn');
				})

				that.gridData.forEach( function(entryLead) {
					if(entryLead.id == lead_id){
						that.selectedLead.entry.assigned_action.splice(0)
						console.log(entryLead)
						entryLead.assigned_action.forEach( function(item) {
							that.selectedLead.entry.assigned_action.push(item)
						})
					}
				})
			},
			changeNoteKey: function(key){
				this.showMoreNote == key
			},
			ChangeRoute( element ) {
				if(element){
					let that = this;
					that.$store.state.selectedPage = element
					that.$router.push({path:'/account/' + element}).catch((e)=>{
						if (
							e.name !== 'NavigationDuplicated' &&
							!e.message.includes('Avoided redundant navigation to current location')
						) {
							console.log(e)
						}
					});
				}
			},
		},
		created(){
			console.log('created')
		},
		mounted() {
			let that = this

			// refreshing smartViews
			that.$store.state.reloadLeftSidebarCompenent = Math.random();
			that.$store.state.checkChange = true;
			// refreshing smartViews

			that.Filters.forEach( function(filter) {
				that.tableSearch.selectedFilter.push(filter.id)
			})

			that.set_email_data();
			that.$i18n.locale = localStorage.getItem('i18n_lang')
			that.gridColumns	=  [
				{ title:  this.$t('Id')		   , column: 'id' 		  , enable: false},
				{ title:  this.$t('Name')		   , column: 'full_name'  , enable: true},
				{ title:  this.$t('FirstName')  , column: 'personal_first_name'  , enable: false},
				{ title: this.$t('LastName')   , column: 'personal_last_name'  , enable: false},
				{ title: this.$t('Status')	   , column: 'status'	  , enable: true},
				{ title: this.$t('Email')	   , column: 'email'	  , enable: true},
				{ title: this.$t('Phone')	   , column: 'personal_phone', enable: true},
				{ title: this.$t('GeneratedOn'), column: 'generatedOn', enable: true},
				{ title: this.$t('Source')	   , column: 'source'	  , enable: false},
				{ title: this.$t('Details'), column: 'details', enable: true},
			];
			// Pusher.logToConsole = true;

			that.GetTeamRole()
			that.CheckDemoData()
			this.GetPayemetStatut();
			
			var pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
				cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
				forceTLS: true
			});
            var channel = pusher.subscribe('team.' + JSON.parse(localStorage.getItem('oml-client')).id + '.leads');
            channel.bind('verified', function(data) {
                var lead = data.lead
                const found = that.gridData.find(element => element.id == lead.id);
                if( typeof(found) != 'undefined' ) {
                    found.email_verified = lead.email_verified
                    found.phone_verified = lead.phone_verified
                }
            });
            channel.bind('fb_webhook', function(data) {
                data = data.lead
                if(data.team_id  == JSON.parse(localStorage.getItem('oml-client')).id ) {
                    let entryLeads = Object.keys( data.entry ), arrayEntry = [], entryValues = Object.values( data.entry )
                    if ( entryLeads.length > 0 ) {
                        entryLeads.forEach( function(item, key) {
                            arrayEntry.push( { name: item, value: entryValues[key], fieldSelected: "custom_field" } )
                        });
                    }
                    var date1 = new Date()
                    var date2 = new Date( data.created_at ) // remember this is equivalent to 06 01 2010
                    //dates in js are counted from 0, so 05 is june
                    var diff = Math.floor(date1.getTime() - date2.getTime());
                    var day = 1000 * 60 * 60 * 24;
                    var days = Math.floor(diff/day);
                    var months = Math.floor(days/31);
                    var years = Math.floor(months/12);
                    let message = '';
                    if( years == 0 && months == 0 ) {
							if( days > 0 ) {
                                message = days + " Days Ago" 
                            } else {
                                if( date1.getHours() - date2.getHours() > 3 ) {
                                    message = 'Today'
                                } else {
                                    message = 'New'
                                }
                            }
                    } else if ( years == 0 && months > 0 ) {
                        if ( months == 1 ) {
                            message = months + " Month Ago"
                        } else {
                            message = months + " Months Ago"
                        }
                    } else if ( years > 0 ) {
                        if ( years == 1 ) {
                            message = years + " Year Ago"
                        } else {
                            message = years + " Years Ago"
                        }
                    }
                    that.gridData.unshift({
                        id					: data.id,
                        full_name			: (data.full_name == null || data.full_name == '') ? data.personal_first_name + ' ' + data.personal_last_name : data.full_name,
                        personal_first_name	: data.personal_first_name == null ? '' : data.personal_first_name,
                        personal_last_name	: data.personal_last_name == null ? '' : data.personal_last_name,
                        status				: data.param.state,
                        score				: data.scoring,
                        email				: data.email == null ? '' : data.email,
                        personal_phone		: data.personal_phone == null ? '' : data.personal_phone,
                        generatedOn			: data.created_at,
                        updated_at			: data.updated_at,
                        source				: data.source.name /* lead.source_id */,
                        color				: data.param.color,
                        statusId			: data.param.id,
                        historic			: data.historiques,
                        notes				: data.notes,
                        email_verified		: data.email_verified,
                        phone_verified		: data.phone_verified,
                        enabled				: data.enabled,
                        entry				: arrayEntry,
                        duration			: message,
                        is_duplicate        : data.is_duplicate,
                        details				: '',
						email_historiques   : data.email_historiques,
                    })
                    
                    entryLeads.forEach( function(item) {
                        that.gridColumns.forEach( function(col) {
                            if( col.title == item ) {
                                that.gridData[that.gridData.length - 1][col.column] = data.entry[item]
                            }
                        } )
                    } )
                    
                    that.leftSidebarInfo()
                }
            });

			that.scroll()
			that.$store.state.selectedPage = this.$route.name
		},
		beforeMount() {
			let that = this;
			that.getUserInfo();
		},
		filters: {
			capitalize: function (str) {
				return str.charAt(0).toUpperCase() + str.slice(1)
			},
			maxLength: function (str) {
				if( str != null && str != '' ) {
					return str.length > 18 ? str.substring(0, 18) + "...." : str
				} else {
					return ''
				}
			},
			maxLengthSource: function (str) {
				if( str != null && str != '' ) {
					return str.length > 38 ? str.substring(0, 38) + " ..." : str
				} else {
					return ''
				}
			}
		},
		updated() {
			if(this.reloadCompenent && this.checkSmartVienwsFilter == true) {
				this.getUserInfo();
				this.smartViewsFilter();
				this.$store.state.checkSmartVienwsFilter = false;
			}
		},
		computed: {
			reloadCompenent () {return this.$store.state.reloadCompenent},
			checkSmartVienwsFilter () {return this.$store.state.checkSmartVienwsFilter},
			smartViewsItem () {return this.$store.state.smartViewsItem},
        },
		watch: {
			newLead: {
				handler: function (val) {
					if( val.personal_first_name != '' || val.personal_last_name != '' ) {
						val.full_name = val.personal_first_name + ' ' + val.personal_last_name
					}
				},
				deep: true
			},
			
			dateRange: function (val) {
				
				if(val == null) {
					this.getLeads(true, 'filter')
				}
			},
			
			DuplicatesLength: function(){
				if(this.selectedLead.entry.duplicates_leads.length > 0){
					return true
				}else{
					return false
				}
			},
		}		
	}
</script>

<style lang="scss">
	@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
	@import url('https://cdn.jsdelivr.net/npm/remixicon@2.5.0/fonts/remixicon.css');

	.activities-tooltip{
		font-size:10px !important;
	}

	#demo .select-dropdown .multiselect-md .fa-caret-down{right: 0px;}
	#demo  .select-dropdown .multiselect-md input {
    font-weight: 400;
    font-size: 12px;
}


	.hr-e7f2f7{
		width:100%;
		height:1px;
		background-color:#e7f2f7;
	}
.bv-modalLeadDetails-header{float:left}
.bv-modalLeadDetails-header .closeme{float:left}
.bv-modalLeadDetails-header .threepoints {float:right}
.threepoints .btn{background:#fff!important}

	.canvas-wrap {
		position: relative;
		width: 300px;
		height: 300px;
		margin-left: auto;
		
		span#procent {
			display: block;
			position: absolute;
			left: 50%;
			top: 54%;
			font-size: 50px;
			transform: translate(-50%, -50%);
			color: #4e4e4e;
			text-align: center;
			font-family: sans-serif;
		}
		span#procent div span {
			font-size: 60px;
		}
		span#procent div {
			line-height: 24px;
		}
		
		span#procent::after {
			content: 'Score';
			font-size: 28px;
			color: #4e4e4e;
		}
	}
	
	#bv-modal-state {
		#bv-modal-state___BV_modal_content_ {
			box-shadow: 0px 0px 10px #ccc;
			border: 0px;
		}
	}
	#bv-modal-state___BV_modal_backdrop_ {
		background-color: transparent;
	}
	
	.m-popline {
		position: fixed;
		background-color: #fff;
		z-index: 9999;
		bottom: 25px;
		left: 25%;
		width: 48em;
		padding: 10px 0px;
		box-shadow: 0px 0px 8px #9E9E9E;
		border-radius: 2px;
		height: 70px;
		img {
			width: 34px;
		}
		.icon-close {
			position: absolute;
			right: 12px;
			top: -1px;
			color: #4a4a4a;
		}
		.show-stats {
			position: absolute;
			right: 40px;
			bottom: 25px;
		}
	}
	
	
	
	#bv-modal-leadDetails, #bv-modal-qualification {
		height:100vh;
		.comment-list {
			.icon-activity {
				padding: 12px 14px;
				font-size: 14px;
				border: 1px solid;
				border-radius: 100%;
				color: #fff;
				background-color: #1fabec;
				width: 40px;
				height: 40px;
			}
			.comment-item {
				border: 1px solid #f7f7f7;
				border-radius: 8px;
				background: #fff;
				padding: 10px 15px;
			}
		}
		
		.modal-dialog {
			width: 62em;
		}
		.modal-body {
			padding: 0px 15px;
		}
		
		.element-details {
			padding: 1rem 0rem 1rem 0rem;
			h5 {
				color: #377dff;
				display:flex;
				align-items: center;
				font-size: 18px;
				min-height: 22px;
			}
		}
		
		.popover-call {
			border: 1px solid #ccc;
			height: 40px;
			width: 40px;
			margin-left: 8px;
			border-radius: 4px;
			color: #fff;
		}
		
		.switch-leads {
			width: 130px;
			position: relative;
			.fa-chevron-right{
				position: absolute;
				top: 10em;
				z-index: 99;
				left: 66em;
			}
			.fa-chevron-left{
				position: absolute;
				top: 10em;
				z-index: 99;
				right: 13.5em;
			}
			.fa-chevron-left, .fa-chevron-right {
				color: #fff;
				background-color: #1fabec;
				border-color: #1fabec !important;
				&:hover {
					background-color: transparent;
					color: #1fabec;
				}
				
			}
			.fa {
				border: 1px solid;
				padding: 12px;
				border-radius: 4px;
				cursor: pointer;
				width: 40px;
				height: 40px;
			}
			
		}
		
		.modal-close {
			cursor: pointer;
			font-size: 28px;
			color: #333;
			margin: 0px 14px;
			padding: 0px 8px 0px 8px;
			height: 4px;
		}
		
		.nav-tabs {
			background-color: #fafafa;
		}
		
		.nav-item {
			.nav-link {
				padding: 0px;
				height: 0px;
				/* padding: 14px 12px; */
				color: #151515;
				font-weight: 600;
				border: 0;
				font-size: 14px;
				&.active {
					color: #f5523c;
					/* border-bottom: 2px solid #f5523c; */
					background-color: transparent;
				}
				&:hover {
					color: #f5523c;
				}
			}
		}
		.modal-content {
			border: 1px solid #f5f5f5 !important;
		}
	}
	
	/* #bv-modal-leadDetails___BV_modal_backdrop_ {
		opacity: 0.8 !important;
	}
	#bv-modal-leadDetails___BV_modal_backdrop_ {
		background-color: #fff !important;
	} */
	
	.tab-myleads {
		overflow-y: auto;
		height: 25em;
		font-size: 14px;
		
		.small-md {
			position: absolute;
			right: 23px;
		}
		
	}
	
	.tab-Activities {
		overflow-y: auto;
		max-height: 68vh;
	}
	
	.tab-myleads, .tab-Activities {
		input[type="text"] {
			border-color: transparent;
			&:hover {
				border-color: #ced4da;
			}
		}
	}
	
	.add-notes {
		background-color: #fff;
		border-top: 1px solid #ececec;
		border-left: 1px solid #eee;
		padding-top: 2px;
		height: 85px;
	}
	.note-textarea[disabled] {
		cursor: not-allowed;
	}
	
	.dropdown-phone-md {
		.dropdown-toggle {
			padding: 0px;
		}
		.dropdown-menu {
			li {
				.dropdown-item {
					&:hover, &:focus {
						color: #fff !important;
					}
				}
				.b-dropdown-text {
					font-size: 13px;
				}
			}
		}
	}
	
	.event_processing {
		position: fixed;
		z-index: 999;
		top: 50%;
		width: 200px;
		margin-left: 0px;
		margin-top: 0px;
		text-align: center;
		padding: 1em 0;
		left: 45%;
	}
	
	.my-Leads-md {
		
		.dropdown-md {
			.dropdown-item {
				font-size: 12px;
			}
			.dropdown-toggle {
				padding: 0px 14px;
			}
		}
		
		.dropdown-action {
			.dropdown-toggle {
				padding: 0px 4px;
				height: 25px;
				display:flex;
				align-items:center;
			}
		}
		
		/* Optional Styles */
		.person {
			background: #ccc;
			border-radius: 2px;
			width: 20%;
			margin: 0 auto 15px auto;
			padding: 15px;
			
			img {
				width: 100%;
				height: auto;
				border-radius: 2px;
			}
			
			p:first-child {
				text-transform: capitalize;
				font-size: 2rem;
				font-weight: 900;
			}
			
			.text-capitalize {
				text-transform: capitalize;
			}
		}
		
	}
	
	.move-draggable-md {
		cursor: move;
		font-size: 14px;
		font-weight: 500;
		color: #545454;
		background-color: #f5f4f4;
	}
	
	.buttonPicker {
		border-radius: 100%;
		border: 1px solid #ccc;
		height: 16px;
		width: 16px;
		vertical-align: middle;
	}
	
	.addCustomStatus {
		font-size: 14px;
		color: #2196F3;
		span {
			cursor: pointer;
		}
	}
	
	input.inputCustomStatus {
		width: 90%;
		height: 32px;
		border-radius: 4px;
		border: 1px solid #afafaf;
		display: inline-block;
		padding: 4px 5px 4px 5px;
		margin-left: 0px;
	}
	
	input {
		&.field-required {
			border-color: #e07e77;
		}
	}
	
	select[disabled] {
		cursor: not-allowed;
	}
	.statusUserAction {
		
		display: block;
		height: calc(1.5em + 0.75rem + 2px);
		padding: 0.375rem 5px 0.375rem 12px;
		font-weight: 400;
		line-height: 1.5;
		border-radius: 4px;
		color: #fff;
		font-size: 13px;
		width: 130px;
		border: 0;
		background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") !important;
	}
	
	select {
		
		-moz-appearance:none; /* Firefox */
		-webkit-appearance:none; /* Safari and Chrome */
		appearance:none;
		background-image: url("data:image/svg+xml;utf8,<svg fill='slategrey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
		background-repeat: no-repeat;
		background-position-x: 96%;
		background-position-y: 8px;
		
	}
	
	.addNote {
		position: absolute;
		top: 5px;
		right: 12px;
		background: #377dff;
		border-color: #377dff;
		border-radius: 3px;
		font-weight: 500;
	}
	
	.note-textarea {
		width: 69%;
		border: 0;
		padding: 10px 5px 12px 12px;
		resize: none;
		font-size: 13px;
		overflow: hidden;
	}
	
	.input-searchZone-md {
		position: relative;
		width: 140px;
		
		.fa {
			position: absolute;
			left: 10px;
			font-weight: 400;
			font-size: 13px;
			color: #dadfe7;
		}

		button{
			background-color:transparent;
			border:0px solid transparent;
			height:26px;
			padding:0;
			margin:0;
			position:absolute;
			top:0;
			bottom:0;
			right:10px;
			margin:auto;
			
			i{
				font-weight: 400;
				font-size: 18px;
				color: #dadfe7;
			}
		}
		
		
		.input-searchZone {
			padding-left: 25px;
			font-size: 12px;
			height: 40px;
			width: 100%;
			color:#999;
			
			&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
				font-size: 12px;
				color:#999;
			}
			
			&:-ms-input-placeholder { /* Internet Explorer 10-11 */
				font-size: 12px;
				color:#999;
			}
			
			&::-ms-input-placeholder { /* Microsoft Edge */
				font-size: 12px;
				color:#999;
			}
		}
	}
	
	.filter-option {
		height: 40px;
		cursor: pointer;
		border: 1px solid #ced4da;
		width: 40px;
		display: flex;
		align-items:center;
		justify-content:center;
		border-radius: 4px;
		.fa {
			font-weight: 400;
			font-size: 15px;
			color: #dadfe7;
		}
	}

	.saveView-btn{
		color:#07B1F4;
		font-size:12px;
		cursor:pointer;
		i{
			font-size:22px;
			color:#07B1F4;
			margin-right:5px;
		}
	}
	
	.lead_status_label {
		padding: 1px 10px 2px 10px;
		border-radius: 2px;
		font-size: 12px;
	}
	
	.small-md {
		font-size: 11px;
	}
	
	.md-custom-select {
		font-size: 13px;
	}
	
	.lead-enrichment {
		margin: 0 auto;
		display: block;
		width: 340px;
		padding: 1rem;
		font-size: 1.2rem;
		background: #377cff;
		border-color: #387bff;
	}
	
	.c-pointer {
		cursor: pointer;
	}
	
	.detail-enrichmens {
		.title-detail {
			line-height: 14px;
			align-items: center;
			color: rgb(48, 57, 64);
			font-size: 10px;
			font-weight: 500;
			background: rgb(245, 246, 247);
			padding: 0px 10px;
			display: flex;
			letter-spacing: 1px;
			border-radius: 2px;
			height: 22px;
		}
		.resume-detail {
			color: rgb(48, 57, 64);
			font-size: 14px;
			line-height: 22px;
			padding: 10px 10px 10px 10px;
		}
	}
	
	.select-md {
		min-height: 31px !important;
	}
	
	.search-md {
		.select-dropdown {
			.multiselect-md {
				.fa {
					color: #dadfe7;
				}
				input {
					color: #999;
				}
				.fa, input {
					font-weight: 400;
					font-size: 13px;
				}
				
			}
		}
	}
	
	.vue-daterange-picker {
		width: 100%;
		
		.reportrange-text {
			font-size: 12px;
			height: 40px;
			display: flex;
			border: 1px solid #ced4da;
			>div {
				display: flex;
				margin-top: auto;
				margin-bottom: auto;
				width: 100%;
				
				.fa-calendar {
					color: #dadfe7;
					margin-top: 2px;
				}
				
				span {
					color: #999;
					padding-left: 5px;
					padding-right: 4px;
					margin-top: 0px;
					font-size: 12px;
					font-weight:400;
					line-height: 17px;
				}
			}
		}
	}
	
	.my-Leads-md {
		.daterangepicker.openscenter {
			transform: translate(-89%) !important;
		}
	}
	
	.daterangepicker.openscenter:after, .daterangepicker.openscenter:before {
		left: 625px !important;
	}
	.applyBtn {
		border-color: #1fabec;
		background: #1fabec;
		&:hover {
			background: #1fabec;
			border-color: #1fabec;
			color: #eee;
		}
	}
	
	.range-span {
		background-color: #377dff;
		border-radius: 50px;
		color: #fff !important;
	}
	.label-range {
		font-size: 10px;
	}
	.init-dateRange {
		position: absolute;
		right: 14px;
		top: 14px;
		color: #6c757d;
	}
	
	.icon-field {
		position: absolute !important;
		top: 10px;
		left: 0px;
		padding-right:4px;
		color: #17a2b8 !important;
		font-size:13px !important;
	}
	
	#bv-modal-changeStatus {
		.modal-body {
			padding: 40px;
			.title-modal {
				font-size: 27px;
				color: #2c3348;
			}
			.p-desc {
				hr {
					margin-top: 30px;
				}
				p {
					font-size: 14px;
					color: #717171;
					margin-bottom: 30px;
				}
			}
		}
	}
	
	.text-ohmylead {
		color: #00bbf6 !important;
	}
	
	.vue-notification-group {
		padding:5px;
		width:425px !important;
		.vue-notification-wrapper {
			margin-top: 10px;
			
			div {
				
				font-size: 14px;
				display: flex;
				
				position: relative;
				padding: 10px 6px;
				border: 1px solid transparent;
				
				.icon-notify {
					font-size: 40px;
				}
				
				&.warn {
					background: #ffb648;
					border-left-color: #f48a06;
					
					.close-notify {
						color: #2c3e50;
					}
				}
				
				&.error {
					background: #E54D42;
					border-left-color: #B82E24;
				}
				
				&.success {
					/* background: #68CD86;
					border-left-color: #42A85F; #e6f5e9 */
					
					background: #e8f9f0;
					color: #155724;
					border: 1px solid #b8f0e6;
				}
				
				.content-notify {
					padding: 10px 0px 10px 14px;
					color: #2c3e50;
					font-size: 13px;
					max-width: 26em;
				}
				.close-notify {
					font-size: 18px;
					position: absolute;
					right: 18px;
					top: 12px;
					cursor: pointer;
					color: #abe6c8;
					display: block;
					padding: 3px 0px;
				}
			}
		}
	}
	#bv-modal-leadDetails{
		font-family: 'Poppins', sans-serif;

		.fs-12{
			font-size:12px !important;
		}
		.fs-13{
			font-size:13px !important;
		}
		.fs-14{
			font-size:14px !important;
		}
		.fs-15{
			font-size:15px !important;
		}
		.fs-16{
			font-size:16px !important;
		}
		.fs-17{
			font-size:17px !important;
		}
		.fw-300{
			font-weight:300 !important;
		}
		.fw-400{
			font-weight:400 !important;
		}
		.fw-500{
			font-weight:500 !important;
		}
		.fw-600{
			font-weight:600 !important;
		}
		.fw-700{
			font-weight:700 !important;
		}
		.color-black{
			color:#000 !important;
		}
		.modal-dialog{
			width: 75%;
			max-width: 75%;
			height:100vh;
			position:fixed;
			top:0;
			right:0;
			border-radius:0 !important;
			margin:0;
			@media screen and (max-width:1200px){
				width: 90%;
				max-width: 90%;
			}
			.modal-content{
				width:100%;
				height:100%;
				border-radius:0;
				.bv-modalLeadDetails-header{
					padding:10px 0;
					width:100%;
					display:flex;
					align-items:center;
					justify-content:space-between;
					button{
						/*background-color:#EEFBFF;
						color:#1FABEC;
						border:0px solid transparent;
						border-radius:5px;
						display:flex;
						align-items:center;
						font-size:15px;
						font-weight:300;
						padding:2px 10px;*/
						
						padding: 0;
						margin: 0;
						background-color: transparent;
						border: 0;
						font-size: 1.5rem;
						font-weight: 700;
						line-height: 1;
						color: #000;
						text-shadow: 0 1px 0 #fff;
						opacity: .5;
						
						&:focus{
							outline:none;
						}
						span{
							position:relative;
							width:20px;
							height:20px;

							&:before{
								content:"";
								width:15px;
								height:2px;
								background-color:#1FABEC;
								transform: rotate(45deg);;
								position:absolute;
								top:0;
								bottom:0;
								left:0;
								margin:auto;
							}
							&:after{
								content:"";
								width:15px;
								height:2px;
								background-color:#1FABEC;
								transform: rotate(-45deg);
								position:absolute;
								top:0;
								bottom:0;
								left:0;
								margin:auto;
							}
						}
					}
				}
				.border-bottom{
					border-bottom:1px solid #F3FBFE !important;
				}
				.tab-content{
					padding-bottom:57px;
					
					.tab-myleads{
						height: calc(100vh - 325px);
						padding-right:15px;
						padding-left:15px;
						
						.tab-myLeads-item{

							.leftTitles{
								display:flex;
								align-items:center;

								i{
									position:initial;
									font-size: 22px;
								}

								label{
									margin-left:25px !important;
								}
							}
						}
						.small-md {
							position: initial;
						}
						.form-control {
							font-size: 14px;
							font-weight:500;
							min-height: 24px;
							height: 30px;
							padding: 0 10px 0 0;
							
						}
					}
				}
				.nav-tabs{
					height:40px;
					border-bottom:1px solid #ECECEC;
					background-color:#fff;

					li{
						height:100%;
						//display:none;
						a{
							height:100%;
							display:flex;
							align-items:center;
							justify-content:center;
							font-weight:400;

							&:hover{
								background-color:rgba(#00BBF6,.3);
								color:#000;
								border-radius:0;
							}

							&.active{
								border-bottom: 2px solid #01A3D6;
								font-weight: 600;
								color: #000;
								background-color: #e7f0ff61;
							}
						}
					}
				}
				.bv-leadDetailsBody-actions{
					position: absolute;
					bottom: 20px;
					left: 0;
					width: 100%;
					padding:0;
					margin:0;
					background-color: #fff;
					border-top:1px solid #F3FBFE;
					.addField-btn{
						color:#1FABEC !important;
						font-size:14px;
						display:block;
						margin:0;
						display:flex;
						align-items:center;

						span{
							width:25px;
							height:25px;
							position:relative;

							&:before{
								content:"";
								width:15px;
								height:2px;
								background-color:#1FABEC;
								transform: rotate(90deg);;
								position:absolute;
								top:0;
								bottom:0;
								left:0;
								margin:auto;
							}
							&:after{
								content:"";
								width:15px;
								height:2px;
								background-color:#1FABEC;
								position:absolute;
								top:0;
								bottom:0;
								left:0;
								margin:auto;
							}
						}

					}
					.deleteField-btn{
						color:#DC3545;
						font-size:14px;
						display:flex;
						align-items:center;

						i{
							font-size:20px;
						}
					}
				}
				.bv-modalLeadDetails-footer{
					position:absolute;
					bottom:0;
					left:0;
					width:100%;
					background-color:#fff;
					padding:20px;
					display:flex;
					align-items:center;
					justify-content:space-between;
					width:100%;
					border-top:1px solid #F3FBFE;
					
					button{
						font-size:14px;
						color:#fff;
						background-color:#00bbf6;
						border:0px solid transparent;
						border-radius:4px;
						padding:8px 10px;
						display:flex;
						align-items:center;
						justify-content:space-around;
						min-width:140px;
						outline:none;

						&:focus{
							outline:none;
						}

						i{
							font-size:25px;
							color:#fff;
						}
					}
				}
				.markAsDup-link{
					font-size:12px;
					font-weight:400;
					color:#DC3545;
					text-decoration:underline;
					margin-top:9px;
					display:block;
				}
			}
			.bv-accordion{
				.card{
					border:0px solid transparent;
				}
				.card-header{
					border-bottom:1px solid #F3FBFE;
					border-top:1px solid #F3FBFE;
					&:first-child{
						border-bottom:0px solid transparent;
					}
					button{
						background-color:#fff;
						border:0px solid transparent;
						display:flex;
						align-items:center;
						justify-content:space-between;
						color:#000;
						font-size:16px;
						font-weight:700;
						-webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
						-moz-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
						box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
						.title{
							display:flex;
							align-items:center;
							span{
								display:inline-block;
								background-color:#DC3545;
								width:22px;
								height:22px;
								border-radius:100%;
								color:#fff;
								display:flex;
								align-items:center;
								justify-content:center;
								font-size:13px;
								font-weight:600;
								margin-left:10px;
							}
						}
						.status-arrow{
							i{
								font-size:24px;
								color:#000;
							}
						}
					}
				}
				.card-body{
					background-color:#F3FBFE;
					height: calc(100vh - 140px);
					overflow-y: auto;
					.bv-accordionDuplicate-container{
						.bv-accordionDuplicate-content{
							.bv-accordionDuplicate-item{
								background-color:#fff;
								border-radius:15px;
								padding:12px;
								margin:10px 0;
								.bv-accordionDuplicateItem-header{
									h3{
										font-size:18px;
										font-weight:600;
										color:#000;
									}

									.bv-accordionDuplicateItem-time{
										color:#8C8C8C;
										font-size:14px;
										font-weight:500;
									}
								}
								.bv-accordionDuplicateItem-content{
									display:flex;
									align-items:flex-end;
									justify-content:space-between;
									.bv-accordionDuplicateItem-infos{
										p{
											color:#8C8C8C;
											font-size:14px;
											font-weight:500;
											margin-bottom:5px;

											&:last-child{
												margin-bottom:0px;
											}
										}

									}
									.bv-accordionDuplicateItem-view{
										button{
											display:flex;
											align-items:center;
											justify-content:center;
											background-color:#F0F0F0;
											border:0px solid transparent;
											color:#1D2841;
											padding:5px 12px;
											border-radius:5px;
											margin-bottom:5px;
											ouline:none;

											i{
												margin-right:6px;
											}

											&:focus{
												outline:none;
											}
										}
									}
								}
							}
						}
					}
					&.bv-accordionActivities-cardBody{
						padding:0;
						.bv-accordionActivities-container{
							display:flex;
							flex-direction:column;
							justify-content:space-between;
							height:100%;
							padding:15px 15px 0 15px;
							.bv-accordionActivities-content{
								height: calc(100% - 85px);
								overflow-y: auto;
								overflow-x: hidden;

								.tab-Activities{
									height:100% !important;
									max-height:100% !important;
								}
							}
							.add-notes{
								border-left:0px solid transparent;
								position: absolute;
								bottom: 0;
								left: 15px;
								width: 100%;
							}
						}
					}

				}
			}
		}
		.bv-modalLeadDetails-leftSide{

		}
		.bv-modalLeadDetails-rightSide{
			position:relative;
			.bv-callDetails{
				background-color:#fff;
				width:100%;
				height:100%;
				position:absolute;
				top:0;
				left:0;
				border-radius:15px;
				box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
				z-index:1;
				border:1px solid #E5E5E5;
				&:before{
					content: "\a";
					border-style: solid;
					border-width: 8px 13px 8px 0;
					border-color: transparent #E5E5E5 transparent transparent;
					position: absolute;
					left: -12px;
					top: 34px;
				}
				&:after{
					content: "\a";
					border-style: solid;
					border-width: 7px 12px 7px 0;
					border-color: transparent #fff transparent transparent;
					position: absolute;
					left: -11px;
					top: 35px;
				}
				&.withoutDetails{
					height:auto;
					margin-top: 15px;

					&:before{
						top: 21px;
					}
					&:after{
						top: 22px;
					}
				}
				.bv-callDetails-container{
					height:100%;
					.bv-callDetails-content{
						padding:15px;
						height:100%;
						.bv-callDetails-header{
							text-align:center;
							position:relative;
							h3{
								font-size:15px;
								color:#000;
								font-weight:700;
							}
							.bv-callDetailsHeader-actions{
								position:absolute;
								top:0;
								bottom:0;
								right:0;
								display:flex;
								align-items:center;
								justify-content:flex-end;
								button{
									width:30px;
									height:30px;
									background-color:#EEFBFF;
									border:0px solid transparent;
									color:#1FABEC;
									display:flex;
									align-items:center;
									justify-content:center;
									border-radius:100%;
									outline:none;
									margin-left:10px;
									i{
										font-size:16px;
									}

									&.rotate-45{
										transform: rotate(45deg);
									}
								}
							}
						}
						.bv-callDetails-deviceStep1{
							display:flex;
							flex-direction:column;
							align-items:center;
							justify-content:center;
							height:100%;
							.bv-callDetailsStep1-img{
								background-color:#EEFBFF;
								width:130px;
								height:130px;
								border-radius:100%;
								display:flex;
								align-items:center;
								justify-content:center;
							}
							p{
								font-size:14px;
								color:#8C8C8C;
								font-weight:500;
								text-align:center;
								margin-top:10px;
							}
							.connect-btn{
								background-color:#6CB871;
								border:0px solid transparent;
								border-radius:6px;
								width:100%;
								max-width:200px;
								text-align:center;
								font-size:14px;
								color:#fff;
								font-weight:500;
								padding:8px 10px;
								cursor:pointer;
								outline:none;
							}
							.or-txt{
								font-size:14px;
								color:#8C8C8C;
								font-weight:500;
								text-align:center;
								width:100%;
								max-width:150px;
								position:relative;
								margin-top:10px;
								&:before{
									content:"";
									height:1px;
									background-color:#707070;
									width:40%;
									position:absolute;
									top:0;
									bottom:0;
									left:0;
									margin:auto;
								}
								&:after{
									content:"";
									height:1px;
									background-color:#707070;
									width:40%;
									position:absolute;
									top:0;
									bottom:0;
									right:0;
									margin:auto;
								}
							}
							a{
								text-decoration:none;
								color:#6CB871;
								font-size:14px;
								font-weight:500;
								text-align:center;
								margin-top:10px;
							}
						}
						.bv-callDetails-deviceStep2{
							display:flex;
							flex-direction:column;
							align-items:center;
							justify-content:center;
							height:100%;
							.bv-callDetailsStep2-img{
								background-color:#EEFBFF;
								width:160px;
								height:160px;
								border-radius:100%;
								display:flex;
								align-items:end;
								justify-content:center;

								svg{
									height:90%;
									width:auto;
								}
							}
							.connect-btn{
								background-color:#6CB871;
								border:0px solid transparent;
								border-radius:6px;
								width:90%;
								text-align:center;
								font-size:14px;
								color:#fff;
								font-weight:500;
								padding:8px 10px;
								cursor:pointer;
								outline:none;
								margin-top:10px;
								display:flex;
								align-items:center;
								justify-content:center;
								i{
									font-size:20px;
									margin-right:5px;
									line-height:20px;
								}
								&.hang-up{
									background-color:#E93529;

									i{
										transform:rotate(135deg);
									}
								}
							}
							.bv-input{
								margin-top:10px;
								width:90%;
								input{
									border:1px solid #B3B3B3;
									border-radius:6px;
									width:100%;
									min-height:38px;
									color:#1D2841;
									padding:0 10px;
								}
							}
							.toRing-content{
								text-align:left;
								width:100%;
								margin-top:10px;

								h4{
									font-size:15px;
								}

								.progress-bar{
									background-color:#1FABEC;
								}

								&.hang-up{
									.progress-bar{
										background-color:#69B96E;
									}
								}
							}
							.call-timer{
								text-align:center;
								margin-top:10px;

								span{
									font-size:15px;
									font-weight:700;
									color:#000;
								}
							}
						}
						.bv-callDetails-moreDetails{
							height:100%;
						}
						.bv-callDetails-hiddenDetails{
							.bv-callDetails-header{
								display:flex;
								align-items:center;
								justify-content:space-between;

								h4{
									font-size:14px;
									color:#000;
									font-weight:500;
									margin:0;
								}

								.call-timer{
									margin-left:10px;
									span{
										font-size:14px;
										color:#000;
										font-weight:700;
									}
								}


								.bv-callDetailsHeader-actions{
									.hangUp-btn{
										background-color:#E93529;
										color:#fff;
										transform:rotate(135deg);
									}
								}
							}
						}
					}
				}

			}
		}
	}

	.oml-vueEditor{
		display:flex;
		flex-direction: column-reverse;
		border:1px solid #E8E8E8;
		border-radius:6px;
		
		

		.ql-container{
			height: calc(100vh - 530px);
			min-height: 50px;
			
			&.ql-snow{
				border:0px solid transparent !important;
			}
		}

		.ql-formats{
			button{
				width:22px !important;

				svg{
					width:16px !important;
					height:16px !important;
				}
			}
		}

		.ql-toolbar{
			border-left:0px solid transparent !important;
			border-right:0px solid transparent !important;
			box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
			position:relative;
			padding-top: 3px !important;
			padding-bottom: 0px !important;

			/*&:before{
				content:"";
				width:50px;
				height:100%;
				background-color:#fff;
				position:absolute;
				top:0;
				right:100%;
			}

			&:after{
				content:"";
				width:50px;
				height:100%;
				background-color:#fff;
				position:absolute;
				top:0;
				left:100%;
			}*/

			&>span{
				border-right:1px solid #d9d9d9;
				margin-bottom: 3px !important;
				min-height: 28px;
				margin-right:0px !important;
				padding:0 5px;

				&:last-child{
					border-right:0px solid transparent;
				}
			}

			.ql-header{
				width:92px !important;
			}

			.ql-size{
				width:72px !important;
			}

			.ql-font{
				width:86px !important;
			}

			
			.ql-formats{
				.ql-placeholder{
					min-width:92px;
				}
			}
		}
	}
	
	
	.modal#bv-modal-leadDetails .modal-dialog {
		position: fixed !important;
		margin: auto !important;
	}
	.modal#bv-modal-leadDetails.fade{
		-webkit-transition: initial;
		transition: initial;
		opacity:1 !important;
	}
	.modal#bv-modal-leadDetails.fade .modal-dialog {
		right: -75% !important;
		opacity:1 !important;
		transform: initial !important;
		transition: all 0.6s !important;
	}
	.modal#bv-modal-leadDetails.fade.show .modal-dialog {
		right: 0 !important;
		opacity:1 !important;
		transition: all 0.6s !important;
	}
	
	.modal-actions{
		display:flex;
		align-items:flex-end;
		justify-content: flex-end;
		height:100%;

		button{
			padding:6px 16px;
			min-width:140px;
			font-size:14px;

			&.cancel-btn{
				background-color:#fff;
				border:1px solid #8d8d8d;
				border-radius:5px;
				color:#8d8d8d;
				height:40px;
			}

			&.save-btn{
				background-color:#06aff3;
				border:1px solid #06aff3;
				border-radius:5px;
				color:#fff;
			}
		}
	}
	
	.ql-editor{
		min-height: auto !important;
		max-height: calc(100vh - 536px) !important;
		height: calc(100vh - 536px) !important;
	}
	.quillWrapper .ql-picker-label{
		font-size:12px !important;
	}
	.ql-snow .ql-toolbar button svg, .quillWrapper .ql-snow.ql-toolbar button svg{
		width:18px !important;
		height:18px !important;
	}
	.w-30px{
		width:30px;
	}
	
	.w-62px{
		width:62px;
	}
	
	.w-85px{
		width:85px;
	}
	
	.w-125px{
		width:125px;
	}
	
	.h-41px{
		height:41px;
	}





	.oml-detailsLead-popup{
			background-color:#fff;
			width:0;
			height:100vh;
			position:fixed;
			top:0;
			right:-800px;
			transition:all 1s;
			z-index:999;
			box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px 9px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
			opacity:0;
			
			&.active{
				width:73%;
				right:0;
				opacity:1;
				transition:all 0.5s;
			}
			
			
			
			.oml-detailsLead-header{
				display:flex;
				align-items:center;
				justify-content:space-between;
				padding:5px 10px;
				
				.oml-detailsLeadHeader-left{
					button{
						&.oml-detailsLead-closeBtn{
							background-color:transparent;
							border:0;
							color:#000;
							
							i{
								font-size:22px;
							}
						}
					}
				}
				
				.oml-detailsLeadHeader-right{
					display:flex;
					align-items:center;
					justify-content:flex-end;
					
					.oml-detailsLead-nav{
						display:flex;
						align-items:stretch;
						
						button{
							border:1px solid #1fabec;
							background-color:transparent;
							border-radius:5px;
							color:#1fabec;
							display:flex;
							align-items:center;
							justify-content:center;
							padding:0 8px;
							
							i{
								font-size:20px;
							}
							
							&.oml-detailsLead-prev{
								border-radius:5px 0 0 5px;
								border-right:0;
							}
							&.oml-detailsLead-next{
								border-radius:0 5px 5px 0;
							}
						}

						
					}
					
					.dropdown{
						i.ri-more-2-fill{
							font-size:22px;
							color:#000;
						}
						
						ul{
							padding:0px !important;

							&.dropdown-menu{
								li{
									a{
										display:flex;
										align-items:center;
										
										i{
											font-size:20px;
											margin-right:5px;
										}
									}
									
									&.sendTo-btn{
										border-bottom:1px solid #ececec;
										position:relative;

										

										form{
											display:flex;
											align-items:center;
											justify-content:space-between;
											padding:5px 10px;

											i{
												font-size:15px;
												color:#b3b3b3;
											}

											& > div{
												display:flex;
												align-items:center;
												color:#1d2841;
												font-size:12px;

												i{
													font-size:15px;
													color:#b3b3b3;
													margin-right:5px;
												}
											}

											position:relative;
										
											.oml-detailsLeadSendTo-workspaces{
												border:1px solid #e2e2e2;
												background-color:#fff;
												border-radius:10px;
												position:absolute;
												top:0;
												right:calc(100% + 10px);
												min-width:290px;
												display:none;

												&:before{
													content:"";
													width: 0; 
													height: 0; 
													border-top: 10px solid transparent;
													border-bottom: 10px solid transparent; 
													border-left:10px solid #e2e2e2;
													position:absolute;
													top:15px;
													left:calc(100% - 0px);
												}

												&:after{
													content:"";
													width: 0; 
													height: 0; 
													border-top: 10px solid transparent;
													border-bottom: 9px solid transparent; 
													border-left:11px solid #fff;
													position:absolute;
													top:15px;
													left:calc(100% - 1px);
												}
												
												.oml-detailsLeadSendToWorkspace-content{
													padding:15px;
													
													h3{
														font-size:13px;
														color:#000;
														font-weight:500;
													}
													
													ul{
														list-style:none;
														padding:0;
														margin:0;
														
														li{
															display:flex;
															align-items:center;
															justify-content:space-between;
															padding:5px 0;
															
															.workspace-item{
																display:flex;
																align-items:center;
																cursor:pointer;
																
																i{
																	color:#b3b3b3;
																	opacity:0;
																}
																
																span{
																	display:inline-block;
																	color:#000;
																	font-size:12px;
																	font-weight:400;
																}
															}
															
															&.checked{
																.workspace-item{
																	i{
																		opacity:1;
																	}
																}
															}
															
															.workspace-total{
																display:inline-block;
																background-color:#4CAF50;
																border-radius:7px;
																padding:2px 6px;
																font-size:7px;
																color:#fff;
																line-height:8px;
															}
														}
													}
												}
												
												.oml-detailsLeadSendToWorkspace-footer{
													border-top:1px solid #e2e2e2;
													padding:8px 15px;
													
													.submitBtn, .bulkBtn{
														border:1px solid #4caf50;
														font-size:11px;
														border-radius:5px;
														background-color:transparent;
														color:#4caf50;
														width:100%;
														display:flex;
														align-items:center;
														justify-content:center;
														padding:4px 0;
														cursor:pointer;
														
														i{
															margin-right:5px;
															font-size:12px;
															color:#4caf50;
														}
													}
												}
											}
											
											&:hover{
												.oml-detailsLeadSendTo-workspaces{
													display:block;
												}
											}
										}
									}
									
									&.delete-btn{
										a{
											color:#f3533b;
											font-size:13px;
											padding:5px 10px;
											
											&:focus{
												color:#fff;
											}

											i{
												font-size:15px;
												color:#f3533b;
												margin-right:5px;
											}
										}
									}
								}
							}
						}
					}
				}
			}

			.oml-detailsLead-body{
				display:flex;
				align-items:stretch;
				width:100%;

				.oml-detailsLeadBody-left{
					display:flex;
					align-items:stretch;
					flex-direction: column;
					width:65%;

					.oml-detailsLeadLeft-header{
						display:flex;
						align-items:center;
						justify-content:space-between;
						padding:8px 15px;

						.oml-detailsLeadLeft-infos{
							width:45%;

							.oml-detailsLeadLeft-name{
								display:flex;
								align-items:center;

								h3{
									font-size:18px;
									font-weight:700;
									margin:0;
									color:#1d2841;
									white-space: nowrap;
									overflow: hidden !important;
									text-overflow: ellipsis;
								}

								button,:any-link{
									width:30px;
									height:30px;
									border-radius:5px;
									border:0px solid transparent;
									margin-left:10px;
									color:#fff;
									display:flex;
									align-items:center;
									justify-content:center;
									text-decoration:none;
									
									i{
										font-size:18px;
									}

									&.call-btn{
										background-color:#4caf50;
									}

									&.whatsapp-btn{
										background-color:#33e555;
									}
								}
							}

							p{
								font-size:13px;
								margin-bottom:0px;
								color:#1d2841;
								display:flex;
								align-items:center;

								b{
									margin-right:5px;
								}

								span{
									white-space: nowrap;
									overflow: hidden !important;
									text-overflow: ellipsis;
									display:inline-block;
									max-width:225px;
								}
								
							}
						}

						.oml-detailsLeadLeft-actions{
							width:55%;

							.multiselect{
								margin:0 10px;
								width:150px;
								cursor:pointer;

								.multiselect__single,.multiselect__placeholder{
									white-space:nowrap;
								}

								.multiselect__option--highlight{
									color:#000 !important;
								}

								.multiselect__content-wrapper{
									width:auto;
									min-width:100%;

									.multiselect__element{
										.multiselect__option{
											background-color:transparent !important;

											span{
												font-size:13px;
												font-weight:400;
											}

											&.multiselect__option--selected{
												background-color:transparent !important;
												padding-left:25px;
												position:relative;

												&:before{
													content:"\EB7B";
													font-family: remixicon!important;
													font-size:16px;
													height:16px;
													width:16px;
													position:absolute;
													right:100%;
													top:0;
													left:0;
													bottom:0;
													margin:auto;
													color:#b3b3b3;
												}

												span{
													font-size:13px;
													font-weight:600;
													color:#000 !important;
												}
											}

											&:hover, &:focus{
												background-color:transparent !important;
												color:#000 !important;
											}
										}
									}
								}

								.multiselect__tags{
									background-color:transparent;

									.multiselect__single{
										background-color:transparent;
										overflow:hidden;
										font-size:14px;
									}
								}

								&.statusSelect{
									border-radius:5px;
									border:0;

									
								}
								
							}

							.duplicateBtn{
								background-color:transparent;
								border:0;
								color:#dc3545;
								text-decoration:underline;
								margin-top:10px;
								font-size:13px;
							}

							.statusUserAction{
								width:150px;
								padding-right:25px;
							}
						}
					}

					.oml-detailsLeadLeft-content{
						height:100%;
						padding:0px 15px 15px 15px;

						.tabs{
							ul{
								width:100%;
								justify-content:start;

								li{
									a{
										padding:10px 20px;
									}
								}
							}

							.tab-content{
								height: calc(100vh - 216px);
								position: relative;
								padding: 10px 15px;
								overflow-y: auto;
								overflow-x:hidden;

								.oml-leadDetails-container{

									.oml-leadDetails-item{
										display:flex;
										align-items:center;
										justify-content:space-between;
										border-bottom:1px solid #f3fbfe;
										padding:15px 0;

										&:first-child{
											padding-top:5px;
										}

										.oml-leadDetails-label{
											display:flex;
											align-items:center;
											font-size:15px;

											i{
												font-size:20px;
												color:#1fabec;
												margin-right:10px;
											}
										}

										.oml-leadDetails-value{
											display:flex;
											align-items:center;

											input{
												text-align:right;
												border:0;
												font-size:15px;
											}
										}

										&.newField{
											background-color:#f8fdff;

											.oml-leadDetailsLabel-textfield{
												.oml-leadDetails-input{
													border:1px solid #ced4da;
													border-radius:4px;
													background-color:transparent;
													height:40px;
													position:relative;

													input{
														padding-left:30px;
														background-color:#fff;
														border:0;
														height:100%;
														font-size:13px;
														min-width:290px;
														border-radius:4px;

														&::placeholder{
															font-size:13px;
														}
													}

													i{
														font-size:20px;
														position:absolute;
														top:0;
														bottom:0;
														left:8px;
														height:20px;
														margin:auto;
														color:#1fabec;
													}
													
												}
											}	

											.oml-leadDetailsValue-textfield{
												input{
													background-color:#fff;
													border:0;
													height:100%;
													font-size:13px;
													min-width:200px;
													border:1px solid #ced4da;
													border-radius:4px;
													height:40px;
													text-align:right;
												}
											}
										}
										
									}

									.oml-leadDetails-actions{
										margin-top:15px;
										position:sticky;
										bottom:-10px;
										left:0;
										width:100%;
										background-color:#fff;
										padding:10px 0 0 0;

										.leftSide{
											display:flex;
											align-items:center;
											margin-left:-15px;
										}

										button{
											border-radius:3px;
											padding:4px 10px;
											background-color:transparent;
											border:0px solid transparent;
											font-size:14px;
											font-weight:500;
											display:flex;
											align-items:center;
											margin:0 10px 0 0;

											i{
												font-size:20px;
												margin-right:6px;
											}
											&.addField-btn{
												color:#1fabec;

												&:focus{
													background-color:rgba(#1fabec,0.1);
												}
											}

											&.minusField-btn{
												color:#dc3545;

												&:focus{
													background-color:rgba(#dc3545,0.1);
												}
											}
										}
									}
								}



								.oml-sendEmail-container{
									.oml-sendEmail-content{
										.oml-addNewTemp{
											width:30px;
											height:30px;
											border:1px solid #1fabec !important;
											border-radius:3px !important;
											background-color:transparent;
											display:flex;
											align-items:center;
											justify-content:center;

											i{
												font-size:22px;
												color:#1fabec;
											}

											span{
												width:18px;
												height:1px;
												background-color:#1fabec;
												position:relative;
												display:inline-block;

												&:after{
													content:"";
													height:18px;
													width:1px;
													background-color:#1fabec;
													position:absolute;
													top:0;
													bottom:0;
													left:0;
													right:0;
													margin:auto;
												}
											}
										}

										label{
											font-size:14px;
										}

										input,select{
											min-height:40px;
											font-size:13px !important;
											color:#1D2841;
											border:1px solid #E8E8E8;

											&::placeholder{
												font-size:13px !important;
												color:#868C9A;
											}

											&:disabled{
												background-color:#fff;
											}
										}

										#template_body{
											height:calc(100vh - 672px);
											min-height:100px;

											.ql-editor{
												height:100% !important;

												&:before{
													font-size:13px !important;
													font-style:initial;
													color:#868C9A;
												}

												p{
													font-size:13px !important;
													color:#1D2841;
												}
											}
										}
									}
								}

								.oml-calendly-container{
									height:100%;
									width:100%;
									display:flex;
									align-items:center;
									justify-content:center;

									.oml-calendly-noResult{
										text-align:center;
										margin-top:10vh;

										img{
											margin-bottom:20px;
											width:150px;
										}

										p{
											font-size:14px;
											color:#8c8c8c;
											margin-bottom:2Opx;
										}

										button{
											min-width:150px;
										}
									}

									.ac-containeer{
										width:100%;

										._3TGGk2HtkH._3xUizGUSyE{
											background-color:transparent !important;
										}

										iframe{
											border:0px solid transparent;
											height:calc(100vh - 242px);
											
										}
									}
								}
							}
						}
					}
					
				}

				.oml-detailsLeadBody-right{
					width:35%;

					.tabs{
						ul{
							width:100%;
							justify-content:start;

							li{
								a{
									padding:10px 20px;
								}
							}
						}

						.nav-item {
							.oml-countDup-title{
								width:18px;
								height:18px;
								background-color:#c5473b;
								border-radius:100%;
								display:flex;
								align-items:center;
								justify-content:center;
								margin-left:5px;
								color:#fff;
								font-size:10px;
							}
						}

						.tab-content{
							height:calc(100vh - 205px);

							.oml-activities-container{
								.oml-activities-content{
									display:flex;
									flex-direction:column;
									align-items:stretch;
									justify-content:space-between;
									height:calc(100vh - 97px);
									background-color:#fafeff;

									.oml-activities-body{
										background-color:#fafeff;
										padding:10px 10px 10px 80px;
										overflow:hidden;
										overflow-y:auto;

										.oml-activities-actLine{
											position:relative;
											min-height:100%;

											&:before{
												content:"";
												width:2px;
												background-color:#ececec;
												position:absolute;
												top:20px;
												bottom:0;
												left:-37px;
											}

											&:after{
												content:"";
												width:6px;
												background-color:#fafeff;
												position:absolute;
												bottom:0;
												left:-39px;
												height:40px;
											}

											.oml-actLine-item{
												position:relative;
												border-radius:8px;
												box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
												margin:20px 0;

												&:before{
													content:"";
													width: 0; 
													height: 0; 
													border-top: 8px solid transparent;
													border-bottom: 8px solid transparent; 
													position:absolute;
													top:0;
													bottom:0;
													right:100%;
													margin:auto;
												}

												.oml-actLineItem-icon{
													width:50px;
													height:50px;
													border-radius:100%;
													display:flex;
													align-items:center;
													justify-content:center;
													position:absolute;
													top:0;
													bottom:0;
													right:calc(100% + 11px);
													margin:auto;
													z-index:1;
													
													i{
														font-size:25px;

														&.icon{
															font-size:16px;
														}
													}
												}
												.oml-actLineItem-content{
													padding:8px;

													.oml-actLineItem-values{
														p{
															width:50%;
															white-space: nowrap;
															overflow: hidden !important;
															text-overflow: ellipsis;
														}
													}

													p{
														font-size:12px;
														margin-bottom:3px;
														display:flex;
														align-items:center;
														color:#1D2841;
														

														b{
															white-space:nowrap;
															font-weight:600;
															text-transform: capitalize;
															margin-right:3px;
															margin-left:5px;

															&:first-child{
																margin-left:0px;
															}
														}

														&.header{
															color:#5F5F5F;
															font-size:12px;
															display:inline-block;
														}

														span{
															width:80%;

															&.substr{
																white-space: nowrap;
																overflow: hidden !important;
																text-overflow: ellipsis;
															}

															p{
																display:inline-block;
															}
														}
													}

													.color-red{
														color:#e61615;
													}

													.color-blue{
														color:#2cafed;
													}

													a{
														text-decoration:none;
														color:#2cafed;
														font-size:13px;
													}

													button.span{
														text-decoration:none;
														color:#2cafed;
														font-size:13px;
														display:block;
														background-color:transparent;
														border:0;
														font-size:12px;
														padding-left:0px;
													}
												}

												&.light-blue{
													background-color:#fff;

													&:before{
														content:"";
														border-right:8px solid #fff;
													}

													.oml-actLineItem-icon{
														background-color:#f2fafe;
														
														i{
															color:#1fabec;
														}
													}
												}

												&.light-yellow{
													background-color:#fff8cf;

													&:before{
														content:"";
														border-right:8px solid #fff8cf;
													}

													.oml-actLineItem-icon{
														background-color:#fff8cf;
														
														i{
															color:#f2d739;
														}
													}
												}

												&.email-box{
													background-color:#fff;

													&:before{
														content:"";
														border-right:8px solid #fff;
													}

													.oml-actLineItem-icon{
														background-color:#fdf7db;
														
														i{
															color:#e2bd21;
														}
													}
												}

												&.light-green{
													background-color:#fff;

													&:before{
														content:"";
														border-right:8px solid #fff;
													}

													.oml-actLineItem-icon{
														background-color:#e9f5e9;
														
														i{
															color:#4caf4f;
														}
													}
												}
											}
										}
									}
									.oml-activities-footer{
										height:150px;
										background-color:#fffde1;
										border-top:1px solid #ececec;
										display:flex;
										flex-direction:column;
										align-items:end;
										justify-content:end;
										
										textarea{
											background-color:transparent;
											border:0;
											padding:10px;
											display:block;
											width:100%;
											resize: none;
											font-size:14px;
										}

										button{
											border-radius:4px;
											font-family: poppins,sans-serif;
											font-size:14px;
											font-weight:400;
											color:#fff;
											line-height: 1.5;
											height:40px;
											background-color:#4caf50;
											border:0;
											padding:0 20px;
											margin:5px;
										}
									}
								}
							}


							.oml-tabDuplicate-container{
								.oml-tabDuplicate-content{
									height:calc(100vh - 97px);
									background-color:#fafeff;
									overflow-y:auto;
									padding:10px;

									.oml-tabDuplicate-item{
										background-color:#fff;
										box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
										border-radius:6px;
										padding:12px;
										margin:10px 0;

										.oml-tabDuplicateItem-header{
											h3{
												font-size:14px;
												font-weight:400;
												color:#1D2841;
											}

											.oml-tabDuplicateItem-time{
												color:#949494;
												font-size:12px;
												font-weight:400;
											}
										}
										.oml-tabDuplicateItem-content{
											display:flex;
											align-items:flex-end;
											justify-content:space-between;

											.oml-tabDuplicateItem-infos{
												p{
													color:#797979;
													font-size:12px;
													font-weight:300;
													margin-bottom:0px;

													b{
														font-weight:500;
													}

													&:last-child{
														margin-bottom:0px;
													}
												}

											}
											.oml-tabDuplicateItem-view{
												button{
													display:flex;
													align-items:center;
													justify-content:center;
													background-color:#F0F0F0;
													border:0px solid transparent;
													color:#1D2841;
													padding:5px 12px;
													border-radius:5px;
													margin-bottom:5px;
													outline:none;

													i{
														margin-right:6px;
													}

													&:focus{
														outline:none;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		
		.oml-detailsLead-overlay{
			background-color:rgba(0,0,0,0.3);
			width:100%;
			height:100vh;
			position:fixed;
			top:0;
			left:0;
			z-index:999;
		}

		.bulkActions-dropdown{
			.dropdown-item{
				padding:5px 10px;
			}
			.bulkActions-sendTo{
				border-top:1px solid #dedede;

				form{
					display:flex;
					align-items:center;
					justify-content:space-between;
					padding:5px 10px;

					i{
						font-size:15px;
						color:#b3b3b3;
					}

					& > div{
						display:flex;
						align-items:center;
						color:#1d2841;
						font-size:12px;

						i{
							font-size:15px;
							color:#b3b3b3;
							margin-right:5px;
						}
					}

					position:relative;
                                    
					.oml-detailsLeadSendTo-workspaces{
						border:1px solid #e2e2e2;
						background-color:#fff;
						border-radius:10px;
						position:absolute;
						top:0;
						left:calc(100% + 10px);
						min-width:290px;
						display:none;

						&:before{
							content:"";
							width: 0; 
							height: 0; 
							border-top: 10px solid transparent;
							border-bottom: 10px solid transparent; 
							border-right:10px solid #e2e2e2;
							position:absolute;
							top:10px;
							right:calc(100% - 0px);
						}

						&:after{
							content:"";
							width: 0; 
							height: 0; 
							border-top: 10px solid transparent;
							border-bottom: 9px solid transparent; 
							border-right:11px solid #fff;
							position:absolute;
							top:10px;
							right:calc(100% - 1px);
						}
						
						.oml-detailsLeadSendToWorkspace-content{
							padding:15px;
							
							h3{
								font-size:13px;
								color:#000;
								font-weight:500;
							}
							
							ul{
								list-style:none;
								padding:0;
								margin:0;
								
								li{
									display:flex;
									align-items:center;
									justify-content:space-between;
									padding:5px 0;
									
									.workspace-item{
										display:flex;
										align-items:center;
										cursor:pointer;
										
										i{
											color:#b3b3b3;
											opacity:0;
										}
										
										span{
											display:inline-block;
											color:#000;
											font-size:12px;
											font-weight:400;
										}
									}
									
									&.checked{
										.workspace-item{
											i{
												opacity:1;
											}
										}
									}
									
									.workspace-total{
										display:inline-block;
										background-color:#4CAF50;
										border-radius:7px;
										padding:2px 6px;
										font-size:12px;
										color:#fff;
										line-height:8px;
									}
								}
							}
						}
						
						.oml-detailsLeadSendToWorkspace-footer{
							border-top:1px solid #e2e2e2;
							padding:8px 15px;
							
							.submitBtn, .bulkBtn{
								border:1px solid #4caf50;
								font-size:11px;
								border-radius:5px;
								background-color:transparent;
								color:#4caf50;
								width:100%;
								display:flex;
								align-items:center;
								justify-content:center;
								cursor:pointer;
								padding:4px 0;
								
								i{
									margin-right:5px;
									font-size:12px;
									color:#4caf50;
								}
							}
						}
					}
					
					&:hover{
						.oml-detailsLeadSendTo-workspaces{
							display:block;
						}
					}
				}
			}
			.bulkActions-delete{
				border-top:1px solid #dedede;

				a{
					color:#f3533b;

					i{
						font-size:15px;
						color:#f3533b;
						margin-right:5px;
					}
				}
			}
		}
		
	.slide-fade-enter-active {
		transition: all .3s ease;
	}
	.slide-fade-leave-active {
		transition: all .3s ease;
	}
	.slide-fade-enter, .slide-fade-leave-to{
		transform: translateX(40px);
		opacity: 0;
	}

	.show-enter-active,
.show-leave-enter {
    transform: translateX(0);
    transition: all .3s linear;
}
.show-enter,
.show-leave-to {
    transform: translateX(100%);
}
</style>
